import { TableName } from './entity';

export interface DistributionStreamSearchRow {
  id?: number;
  name: string;
  matches: ReadonlyArray<{ ecn: string; alias: string }>;
}

export interface DistributionStream {
  id?: number;
  name: string;
  numberLinked: number;
  marginCategoryRule: readonly MarginCategoryRule[];
  tradingGroupRule: readonly TradingGroupRule[];
  streamAliasRule: readonly StreamAliasRule[];
  publishedBucketsRule: readonly PublishedBucketsRule[];
}

export interface MarginCategoryRule {
  id?: number;
  product: string;
  currency: string;
  tenor: string;
  bidAsk: string;
  result: string;
}
export interface TradingGroupRule {
  id?: number;
  product: string;
  currency: string;
  tenor: string;
  result: string;
}
export interface StreamAliasRule {
  id?: number;
  ecn: string;
  result: string;
}
export interface PublishedBucketsRule {
  id?: number;
  ecn: string;
  currency: string;
  streamAlias: string;
  result: string;
}

export const modelDistributionStream = (): DistributionStream => ({
  id: undefined,
  name: '',
  numberLinked: 0,
  marginCategoryRule: [createDistLine('marginCategoryRule', 0)],
  tradingGroupRule: [createDistLine('tradingGroupRule', 0)],
  streamAliasRule: [],
  publishedBucketsRule: [],
});

export const createDistLine = <T extends TableName<DistributionStream>>(
  columnName: T,
  id: number,
): DistributionStream[T][0] => {
  switch (columnName) {
    case 'marginCategoryRule':
      const resultMargin: MarginCategoryRule = {
        id,
        product: '*',
        currency: '*',
        tenor: '*',
        bidAsk: '',
        result: '',
      };
      return resultMargin;
    case 'tradingGroupRule':
      const resultTrading: TradingGroupRule = {
        id,
        product: '*',
        currency: '*',
        tenor: '*',
        result: '',
      };
      return resultTrading;
    case 'streamAliasRule':
      const resultStream: StreamAliasRule = {
        id,
        ecn: '360T',
        result: '',
      };
      return resultStream;
    case 'publishedBucketsRule':
      const resultBucket: PublishedBucketsRule = {
        id,
        ecn: '360T',
        currency: '*',
        streamAlias: '*',
        result: '',
      };
      return resultBucket;
    default:
      throw Error();
  }
};
