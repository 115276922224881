import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { App } from 'app/App';
import { createAppStore } from 'store/store';

import 'index.scss';
import { sgwtConnect } from 'utils/sgwtConnect';
type CustomElement<T> = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement> & T, HTMLElement>;
declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['sgwt-super-header']: CustomElement<any>;
    }
  }
}

sgwtConnect.requestAuthorization();
if (sgwtConnect.isAuthorized()) {
  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement!);
  root.render(
    <Provider store={createAppStore()}>
      <sgwt-super-header container="container-fluid" sg-connect-support="sg-connect-v2" />
      <App />
    </Provider>,
  );
} else {
  const authorizationError = sgwtConnect.getAuthorizationError();
  if (authorizationError) {
    console.log('failed to be authorized', authorizationError);
    window.location.href = `${window.sgmeConfiguration.redirect_uri}${window.location.href}`;
  } else {
    sgwtConnect.requestAuthorization();
  }
}
