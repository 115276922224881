import { Editable, LimitedEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import { isRequiredValidator, useCurrencyValidator, Validator } from 'app/utils/validators';
import { PublishedBucketsRule } from 'model/distribution-stream';
import React, { useCallback, useMemo } from 'react';
import { CommonRowProps } from '../util/table';
import { DistributionStreamRuleTableProps } from './types';

interface PublishedBucketsRuleTableProps extends DistributionStreamRuleTableProps<'publishedBucketsRule'> {
  ecns: readonly string[];
  algoTypes: readonly string[];
  currencies: readonly string[];
}
export function PublishedBucketsRuleTable(props: PublishedBucketsRuleTableProps) {
  const table = useMemo(
    () => createTable(props.values, ['ecn', 'streamAlias', 'currency', 'result', 'id']),
    [props.values],
  );
  const validateCurrency = useCurrencyValidator(props.currencies);

  return (
    <table className={`table table-bordered editable-table ${props.display ? '' : 'd-none'}`}>
      <thead>
        <tr>
          <th>Ecn</th>
          <th>Stream Alias</th>
          <th>Currency</th>
          <th className="result-column">Result</th>
          {props.editing && (
            <th>
              <Icon type="add" onClick={() => props.addLine()} color="info" />{' '}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <EditableTable<keyof PublishedBucketsRule, PublishedBucketsRule>
          values={table}
          editing={props.editing}
          useOtherConditionGet={false}
          rowId="id"
          resultKey="result"
          size={4}
          addLine={props.addLine}
          deleteLine={props.deleteLine}
        >
          {(value, index) => (
            <PublishedBucketsRule
              value={value}
              ecns={props.ecns}
              editing={props.editing}
              index={index}
              onlyResult={false}
              onChange={props.onChange}
              validateCurrency={validateCurrency}
            />
          )}
        </EditableTable>
      </tbody>
    </table>
  );
}
interface PublishedBucketRuleProps extends CommonRowProps<PublishedBucketsRule> {
  ecns: readonly string[];
  validateCurrency: Validator;
}

const PublishedBucketsRule = (props: PublishedBucketRuleProps) => {
  const ecnChange = useCallback(
    (value: string) => props.onChange('ecn', props.index, value),
    [props.onChange, props.index],
  );
  const streamAliasChange = useCallback(
    (value: string) => props.onChange('streamAlias', props.index, value),
    [props.onChange, props.index],
  );
  const currencyChange = useCallback(
    (value: string) => props.onChange('currency', props.index, value),
    [props.onChange, props.index],
  );
  const resultChange = useCallback(
    (value: string) => props.onChange('result', props.index, value),
    [props.onChange, props.index],
  );

  return (
    <>
      <Cell editing={props.editing} displayed={props.value.ecn.displayed} length={props.value.ecn.length}>
        <LimitedEditable
          possibleValues={props.ecns}
          editing={props.editing}
          value={props.value.ecn.value}
          onChange={ecnChange}
        />
      </Cell>
      <Cell
        editing={props.editing}
        displayed={props.value.streamAlias.displayed}
        length={props.value.streamAlias.length}
      >
        <Editable
          editing={props.editing}
          value={props.value.streamAlias.value}
          onChange={streamAliasChange}
          validate={isRequiredValidator}
        />
      </Cell>
      <Cell editing={props.editing} displayed={props.value.currency.displayed} length={props.value.currency.length}>
        <Editable
          editing={props.editing}
          value={props.value.currency.value}
          onChange={currencyChange}
          validate={props.validateCurrency}
        />
      </Cell>
      <Cell editing={props.editing} displayed={props.value.result.displayed} length={props.value.result.length} result>
        <Editable
          editing={props.editing}
          onChange={resultChange}
          value={props.value.result.value}
          validate={isRequiredValidator}
          defaultValue="REJECTED"
        />
      </Cell>
    </>
  );
};
