import { Referentials } from 'model/referentials';
import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';

export interface ReferentialsState {
  values: Referentials;
}

const initialState: ReferentialsState = {
  values: {
    algoTypes: [],
    competition: [],
    currencySymbols: [],
    ecns: [],
    internalExternal: [],
    marginCategories: [],
    newMarginCategories: [],
    products: [],
    tenors: [],
    tradingGroups: [],
  },
};

export const referentialsReducer: Reducer<ReferentialsState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'Referentials.fetched':
      return { type: 'client-success', values: action.referentials };
    default:
      return state;
  }
};
