import { combineReducers } from 'redux';
import { alertsReducer } from './state/alerts/alerts.reducer';
import { entityListReducer } from './state/entity-list/entity-list.reducer';
import { massiveUploadReducer } from './state/massive-upload/massive-upload.reducer';
import { referentialsReducer } from './state/referentials/referentials.reducer';
import { searchResultReducer } from './state/search-result/search-result.reducer';
import { algoStreamSearchReducer } from './state/search/algo-stream-search-form/algo-stream-search-form.reducer';
import { clientSearchByStreamNameReducer } from './state/search/client-search-by-stream-name-form/client-search-by-stream-name-form.reducer';
import { clientSearchReducer } from './state/search/client-search-form/client-search-form.reducer';
import { distributionStreamSearchReducer } from './state/search/distribution-stream-search-form/distribution-stream-search-form.reducer';
import { userReducer } from './state/user/user.reducer';

export const appReducer = combineReducers({
  clientSearchForm: clientSearchReducer,
  clientSearchByStreamNameForm: clientSearchByStreamNameReducer,
  distributionStreamSearchForm: distributionStreamSearchReducer,
  algoStreamSearchForm: algoStreamSearchReducer,
  searchResult: searchResultReducer,
  massiveUploadForm: massiveUploadReducer,
  entityList: entityListReducer,
  referentials: referentialsReducer,
  user: userReducer,
  alerts: alertsReducer,
});
