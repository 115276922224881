import { DistributionStreamSearchRow } from 'model/distribution-stream';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import { distributionStreamSearchSuccess } from 'store/state/search-result/search-result.actions';
import { getJSON } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

const searchByName = (name: string) =>
  getJSON<DistributionStreamSearchRow[]>(
    `${getConfig().api_url}api/distributionstream/search?name=` + encodeURIComponent(name),
  );

export const distributionStreamSearchEpic =
  (requestResults = searchByName): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('SearchForm.DistributionStream.formSubmitted' as const),
      withLatestFrom(state$),
      switchMap(([, { distributionStreamSearchForm }]) =>
        requestResults(distributionStreamSearchForm.name).pipe(
          map((c) => distributionStreamSearchSuccess(c, distributionStreamSearchForm.name)),
          catchError((e) => {
            console.error(e);
            return of(addAlert('error while searching distribution stream'));
          }),
        ),
      ),
    );
