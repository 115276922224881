import { DistributionStream } from 'model/distribution-stream';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import {
  displayDistributionStream,
  fetchDistributionStreamDetails,
  highlightEntity,
} from 'store/state/entity-list/entity-list.actions';
import { EntityListState } from 'store/state/entity-list/entity-list.reducer';
import { execRequest } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

export const getDistByName = (name: string) =>
  execRequest<DistributionStream>({
    url: `${getConfig().api_url}api/distributionstream?name=` + encodeURIComponent(name),
  });

export const addDistributionStream = (
  requestResults: (s: string) => Observable<AjaxResponse<DistributionStream>>,
  action: ReturnType<typeof fetchDistributionStreamDetails>,
  entityList: EntityListState,
): Observable<AppAction> => {
  const entity = entityList.entities.find((e) => e.type === 'distribution-stream' && e.value.name === action.name);

  return entity
    ? of(highlightEntity(entity))
    : requestResults(action.name).pipe(
        map((e) => {
          if (e.status === 200) {
            return displayDistributionStream(e.response);
          }
          return addAlert(`distribution stream "${action.name}" : not found`);
        }),
        catchError((e) => {
          console.error(e);
          return of(addAlert(`distribution stream "${action.name}" : server error`));
        }),
      );
};

export const distributionStreamDetailsEpic =
  (requestResults = getDistByName): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('EntityList.fetchDistributionStreamDetails' as const),
      withLatestFrom(state$),
      switchMap(([s, { entityList }]) => addDistributionStream(requestResults, s, entityList)),
    );
