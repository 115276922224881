import { Icon } from 'app/utils/icons';
import { Message } from 'model/Alert';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import { removeAlert } from 'store/state/alerts/alerts.actions';

function Alert(props: { message: Message; remove: (id: number) => void }) {
  const doRemove = useCallback(() => props.remove(props.message.id), [props.remove, props.message.id]);
  return (
    <div className="alert alert-danger">
      <table className="message">
        <tbody>
          <tr>
            <td>{props.message.text}</td>
            <td className="close-message">
              <Icon type="close" onClick={doRemove} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function Alerts(props: { messages: Message[]; remove: (id: number) => void }) {
  return (
    <div className="alert-list">
      {props.messages.map((m) => (
        <Alert key={m.id} message={m} remove={props.remove} />
      ))}
    </div>
  );
}

function mapStateToProps(state: AppState) {
  return { messages: state.alerts.messages };
}

const mapDispatchToProps = {
  remove: removeAlert,
};

export const AlertsConnected = connect(mapStateToProps, mapDispatchToProps)(Alerts);
