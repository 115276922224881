export const bdrInputChanged = (bdrId: string) =>
  ({
    type: 'SearchForm.ClientByStreamName.bdrInputChanged',
    bdrId,
  } as const);

export const formSubmitted = () =>
  ({
    type: 'SearchForm.ClientByStreamName.formSubmitted',
  } as const);
