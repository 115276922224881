import { DependencyList, useCallback, useEffect, useState } from 'react';
import { Observable, Subject } from 'rxjs';

export type EventTrigger<EventValue> = (e: EventValue) => void;
export type ObservableFactory<EventValue, State> = (eventSource$: Observable<EventValue>) => Observable<State>;

export function useEventCallback<Input, State extends string | undefined>(
  callback: ObservableFactory<Input, State>,
  deps: DependencyList,
): [EventTrigger<Input>, string | undefined] {
  let event$: Subject<Input>;
  const [current, setCurrent] = useState<State>();

  useEffect(() => {
    event$ = new Subject<Input>();
    const value$ = callback(event$);
    const subscription = value$.subscribe((v) => setCurrent(v));
    return () => {
      event$.complete();
      subscription.unsubscribe();
    };
  }, deps);

  return [useCallback((e: Input) => event$.next(e), deps), current];
}
