import { Entity, EntityTableName } from 'model/entity';
import { Referentials } from 'model/referentials';
import { useCallback } from 'react';
import React from 'react';
import { AlgoStreamComponent } from './algo-stream/algo-stream';
import { ClientComponent } from './client/client';
import { DistributionStreamComponent } from './distribution-stream/distribution-stream';

interface EntityComponentProps {
  value: Entity;
  fetchDistributionStreamDetails: (name: string) => void;
  fetchAlgoStreamDetails: (name: string) => void;
  referentials: Referentials;
  editFormChanged: (
    entityId: number,
    tableName: EntityTableName,
    columnName: string,
    rowId: number,
    value: string,
  ) => void;
  deleteLine: (entityId: number, table: EntityTableName, line: number) => void;
  addLine: (entityId: number, table: EntityTableName, afterIndex?: number) => void;
}

export function EntityContent(props: EntityComponentProps) {
  const doAddLine = useCallback(
    (table: EntityTableName, afterIndex?: number) => props.addLine(props.value.id, table, afterIndex),
    [props.value.id],
  );
  const doDeleteLine = useCallback(
    (table: EntityTableName, line: number) => props.deleteLine(props.value.id, table, line),
    [props.value.id],
  );
  const doEditFormChanged = useCallback(
    (a: EntityTableName, b: any, c: number, d: string) => props.editFormChanged(props.value.id, a, b as string, c, d),
    [props.value.id],
  );

  switch (props.value.type) {
    case 'mass-upload':
    case 'client':
      return (
        <ClientComponent
          ecns={props.referentials.ecns}
          id={props.value.id}
          client={props.value.value}
          fetchDistribution={props.fetchDistributionStreamDetails}
          fetchAlgo={props.fetchAlgoStreamDetails}
          editing={props.value.state !== 'reading'}
          onChange={doEditFormChanged}
          deleteLine={doDeleteLine}
          addLine={doAddLine}
          products={props.referentials.products}
          currencies={props.referentials.currencySymbols}
          entityType={props.value.type}
        />
      );
    case 'algo-stream':
      return (
        <AlgoStreamComponent
          algoTypes={props.referentials.algoTypes}
          internalExternals={props.referentials.internalExternal}
          value={props.value.value}
          editing={props.value.state !== 'reading'}
          onChange={doEditFormChanged}
          deleteLine={doDeleteLine}
          addLine={doAddLine}
          currencyGroups={['G10', 'EMERGING']}
        />
      );
    case 'distribution-stream':
      return (
        <DistributionStreamComponent
          ecns={props.referentials.ecns}
          marginCategories={props.referentials.marginCategories}
          products={props.referentials.products}
          tenors={props.referentials.tenors}
          tradingGroups={props.referentials.tradingGroups}
          editing={props.value.state !== 'reading'}
          id={props.value.id}
          currencies={props.referentials.currencySymbols}
          value={props.value.value}
          algoTypes={props.referentials.algoTypes}
          onChange={doEditFormChanged}
          deleteLine={doDeleteLine}
          addLine={doAddLine}
        />
      );
  }
}
