import React from 'react';
import { AddMassUploadPanelConnected } from './add-massive-upload';
import { AddPanelConnected } from './add-panel';
import { AlgoStreamSearchFormConnected } from './algo-stream-search-form';
import { ClearConnected } from './clear-button';
import { ClientSearchForm } from './client-search-form';
import { DistributionStreamSearchFormConnected } from './distribution-stream-search-form';

export function SearchColumn() {
  return (
    <div>
      <h1>Search</h1>
      <div className="row mb-4">
        <AddPanelConnected />
      </div>
      <div className="row mb-4">
        <AddMassUploadPanelConnected />
      </div>
      <div className="row mb-4">
        <div className="col">
          <ClientSearchForm />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <DistributionStreamSearchFormConnected />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <AlgoStreamSearchFormConnected />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <ClearConnected />
        </div>
      </div>
    </div>
  );
}
