import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import { displayMassUpload } from 'store/state/entity-list/entity-list.actions';
import { displayBdrIdsMassUpload } from 'store/state/search-result/search-result.actions';

import { Client } from 'model/client';

interface AddPanelProps {
  displayBdrIdsMassUpload: () => void;
  displayMassUpload: (client: Client) => void;
  isAdmin: boolean;
}

const AddPanel = (props: AddPanelProps) => {
  const doEmptyMassUpload = (args: FormEvent) => {
    props.displayBdrIdsMassUpload();
    props.displayMassUpload({
      bdrId: '0',
      id: undefined,
      name: 'MASSIVE UPLOAD',
      disabled: false,
      comment: null,
      executionPreventionRule: [],
      rfsDistributionStreamRule: [],
      algoFeesRule: [],
      espDistributionStreamRule: [],
    });
  };

  return (
    <>
      <div className="col">
        <button
          disabled={!props.isAdmin}
          className="btn btn-primary btn-block btn-sm h-100"
          onClick={doEmptyMassUpload}
        >
          Mass Upload
        </button>
      </div>
    </>
  );
};
const mapStateToProps = (state: AppState) => ({
  isAdmin: state.user.user.isAdmin,
});
const mapDispatchToProps = {
  displayBdrIdsMassUpload,
  displayMassUpload,
};

export const AddMassUploadPanelConnected = connect(mapStateToProps, mapDispatchToProps)(AddPanel);
