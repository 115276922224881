import { BidAskEditable, Editable, LimitedEditable, TenorEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import { useCurrencyValidator, Validator } from 'app/utils/validators';
import { MarginCategoryRule } from 'model/distribution-stream';
import React, { memo, useCallback, useMemo } from 'react';
import { CommonRowProps } from '../util/table';
import { DistributionStreamRuleTableProps } from './types';

interface MarginCategoryRuleTableProps extends DistributionStreamRuleTableProps<'marginCategoryRule'> {
  marginCategories: readonly string[];
  products: readonly string[];
  tenors: readonly string[];
  currencies: readonly string[];
}

function getColumns({
  useBidAsk,
  useResult,
}: {
  useBidAsk: boolean;
  useResult: boolean;
}): Array<keyof MarginCategoryRule> {
  const columns: Array<keyof MarginCategoryRule> = ['product', 'currency', 'tenor'];
  if (useBidAsk) {
    columns.push('bidAsk');
  }
  if (useResult) {
    columns.push('result');
  }
  columns.push('id');
  return columns;
}
export const MarginCategoryRuleTable = memo(function MarginCategoryRuleTable_(props: MarginCategoryRuleTableProps) {
  const useBidAsk = props.values.some((c) => c.bidAsk) || props.editing;
  const useResult = !props.values.some((c) => c.bidAsk) || props.editing;
  const marginTable = useMemo(
    () => createTable(props.values, getColumns({ useBidAsk, useResult })),
    [props.values, useBidAsk],
  );

  const validateCurrency = useCurrencyValidator(props.currencies);
  return (
    <table className={`table table-bordered editable-table ${props.display ? '' : 'd-none'}`}>
      <MarginHeader addLine={props.addLine} editing={props.editing} useBidAsk={useBidAsk} />
      <tbody>
        <EditableTable<keyof MarginCategoryRule, MarginCategoryRule>
          values={marginTable}
          editing={props.editing}
          rowId="id"
          useOtherConditionGet
          resultKey={useBidAsk ? 'bidAsk' : 'result'}
          size={props.editing ? 5 : 4}
          defaultResult="REJECTED"
          addLine={props.addLine}
          deleteLine={props.deleteLine}
        >
          {(value, index, onlyResult) => (
            <MarginCategory
              value={value}
              index={index}
              onlyResult={onlyResult}
              editing={props.editing}
              onChange={props.onChange}
              products={props.products}
              tenors={props.tenors}
              marginCategories={props.marginCategories}
              validateCurrency={validateCurrency}
            />
          )}
        </EditableTable>
      </tbody>
    </table>
  );
});

const MarginHeader = (props: { editing: boolean; useBidAsk: boolean; addLine: () => void }) => (
  <thead>
    <tr>
      <th style={{ width: '8em' }}>Product</th>
      <th>Currency</th>
      <th style={{ width: '15em' }}>Tenor</th>
      {(props.editing || props.useBidAsk) && <th className="result-column">Bid/Ask</th>}
      {(props.editing || !props.useBidAsk) && (
        <th style={{ minWidth: '6.2em' }} className="result-column">
          Result
        </th>
      )}
      {props.editing && (
        <th>
          <Icon type="add" onClick={props.addLine} color="info" />{' '}
        </th>
      )}
    </tr>
  </thead>
);
interface MaginCategoryProps extends CommonRowProps<MarginCategoryRule> {
  products: readonly string[];
  tenors: readonly string[];
  marginCategories: readonly string[];
  validateCurrency: Validator;
}
const defaultResult = { label: 'REJECTED', value: '' };
const MarginCategory = (props: MaginCategoryProps) => {
  const productChange = useCallback(
    (value: string) => props.onChange('product', props.index, value),
    [props.onChange, props.index],
  );
  const currencyChange = useCallback(
    (value: string) => props.onChange('currency', props.index, value),
    [props.onChange, props.index],
  );
  const tenorChange = useCallback(
    (value: string) => props.onChange('tenor', props.index, value),
    [props.onChange, props.index],
  );
  const bidAskChange = useCallback(
    (value: string | undefined) => props.onChange('bidAsk', props.index, value as string),
    [props.onChange, props.index],
  );
  const resultChange = useCallback(
    (value: string) => props.onChange('result', props.index, value),
    [props.onChange, props.index],
  );

  return (
    <>
      <Cell editing={props.editing} displayed={props.value.product.displayed} length={props.value.product.length}>
        <LimitedEditable
          defaultValue="*"
          disabled={props.onlyResult}
          possibleValues={props.products}
          editing={props.editing}
          value={props.value.product.value}
          onChange={productChange}
        />
      </Cell>
      <Cell editing={props.editing} displayed={props.value.currency.displayed} length={props.value.currency.length}>
        <Editable
          disabled={props.onlyResult}
          editing={props.editing}
          value={props.value.currency.value}
          onChange={currencyChange}
          validate={props.validateCurrency}
        />
      </Cell>
      <Cell editing={props.editing} displayed={props.value.tenor.displayed} length={props.value.tenor.length}>
        <TenorEditable
          disabled={props.onlyResult}
          possibleValues={props.tenors}
          editing={props.editing}
          value={props.value.tenor.value}
          onChange={tenorChange}
        />
      </Cell>
      <Cell
        editing={props.editing}
        displayed={props.value.bidAsk?.displayed ?? false}
        length={props.value.bidAsk?.length ?? 1}
        result
      >
        <BidAskEditable
          editing={props.editing}
          onChange={bidAskChange}
          value={props.value.bidAsk?.value ?? ''}
          possibleValues={props.marginCategories}
        />
      </Cell>
      <Cell
        editing={props.editing}
        displayed={props.value.result?.displayed ?? false}
        length={props.value.result?.length ?? 1}
        result
      >
        <LimitedEditable
          possibleValues={props.marginCategories}
          editing={props.editing}
          defaultValue={defaultResult}
          value={props.value.result?.value}
          onChange={resultChange}
        />
      </Cell>
    </>
  );
};
