import { Entity } from 'model/entity';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import { EntityComponentConnected } from './entity';

interface EntityListProps {
  readonly entities: readonly Entity[];
  readonly isAdmin: boolean;
}

const EntityList = (props: EntityListProps) => (
  <div>
    {props.entities.map((c) => (
      <EntityComponentConnected isAdmin={props.isAdmin} value={c} key={c.id} />
    ))}
  </div>
);

const mapStateToProps = (state: AppState) => ({
  entities: state.entityList.entities,
  isAdmin: state.user.user.isAdmin,
});

export const EntityListConnected = connect(mapStateToProps)(EntityList);
