import { usePreventCallback } from 'app/utils/event-handler-helpers';
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import { newAlgoStream, newDistributionStream } from 'store/state/entity-list/entity-list.actions';

interface AddPanelProps {
  newDistributionStream: () => void;
  newAlgoStream: () => void;
  isAdmin: boolean;
}

const AddPanel = (props: AddPanelProps) => {
  const doNewDistributionStream = usePreventCallback(props.newDistributionStream);
  const doNewAlgoStream = usePreventCallback(props.newAlgoStream);

  return (
    <>
      <div className="col">
        <button
          disabled={!props.isAdmin}
          className="btn btn-primary btn-block btn-sm h-100"
          onClick={doNewDistributionStream}
        >
          New Distribution Stream
        </button>
      </div>
      <div className="col">
        <button disabled={!props.isAdmin} className="btn btn-primary btn-block btn-sm h-100" onClick={doNewAlgoStream}>
          New Algo Fees Stream
        </button>
      </div>
    </>
  );
};
const mapStateToProps = (state: AppState) => ({
  isAdmin: state.user.user.isAdmin,
});
const mapDispatchToProps = {
  newDistributionStream,
  newAlgoStream,
};

export const AddPanelConnected = connect(mapStateToProps, mapDispatchToProps)(AddPanel);
