import { Editable, LimitedEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import { isRequiredValidator } from 'app/utils/validators';
import { EspDistributionStreamRule } from 'model/client';
import React, { useCallback } from 'react';
import { CommonRowProps } from '../util/table';
import { ClientTableProps } from './types';

interface EspDistributionProps extends ClientTableProps<'espDistributionStreamRule'> {
  ecns: readonly string[];
  products: readonly string[];
}

export function EspDistribution(props: EspDistributionProps) {
  const table = createTable(props.values, ['ecn', 'product', 'result', 'id']);
  return (
    <table className="table table-bordered editable-table">
      <thead>
        <tr>
          <th>ECN</th>
          <th>Products</th>
          <th className="result-column">Result</th>
          {props.editing && (
            <th>
              <Icon type="add" onClick={() => props.addLine()} color="info" />{' '}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <EditableTable<keyof EspDistributionStreamRule, EspDistributionStreamRule>
          values={table}
          addLine={props.addLine}
          resultKey="result"
          rowId="id"
          useOtherConditionGet={false}
          deleteLine={props.deleteLine}
          editing={props.editing}
          size={2}
        >
          {(value, index, onlyResult) => (
            <EspDistributionRow
              products={props.products}
              value={value}
              index={index}
              onChange={props.onChange}
              onlyResult={onlyResult}
              editing={props.editing}
              ecns={props.ecns}
            />
          )}
        </EditableTable>
      </tbody>
    </table>
  );
}

interface EspDistributionRowProps extends CommonRowProps<EspDistributionStreamRule> {
  ecns: readonly string[];
  products: readonly string[];
}

const EspDistributionRow = (props: EspDistributionRowProps) => {
  console.log('products', props.products);
  const ecnChange = useCallback(
    (value: string) => props.onChange('ecn', props.index, value),
    [props.onChange, props.index],
  );
  const productChange = useCallback(
    (value: string) => props.onChange('product', props.index, value),
    [props.onChange, props.index],
  );
  const resultChange = useCallback(
    (value: string) => props.onChange('result', props.index, value),
    [props.onChange, props.index],
  );
  return (
    <>
      <Cell editing={props.editing} length={props.value.ecn.length} displayed={props.value.ecn.displayed}>
        <LimitedEditable
          onChange={ecnChange}
          defaultValue="*"
          possibleValues={props.ecns}
          editing={props.editing}
          value={props.value.ecn.value}
          validate={isRequiredValidator}
        />
      </Cell>
      <Cell editing={props.editing} length={props.value.product.length} displayed={props.value.product.displayed}>
        <LimitedEditable
          onChange={productChange}
          editing={props.editing}
          value={props.value.product.value}
          possibleValues={props.products}
        />
      </Cell>
      <Cell editing={props.editing} length={props.value.result.length} displayed={props.value.result.displayed} result>
        <Editable onChange={resultChange} editing={props.editing} value={props.value.result.value} />
      </Cell>
    </>
  );
};
