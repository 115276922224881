import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';

interface DistributionStreamSearchFormState {
  readonly name: string;
}

const initialState: DistributionStreamSearchFormState = {
  name: '',
};

export const distributionStreamSearchReducer: Reducer<DistributionStreamSearchFormState, AppAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'SearchForm.DistributionStream.nameChanged':
      return { ...state, name: action.name };

    case 'SearchForms.reset':
      return { ...state, name: initialState.name };

    default:
      return state;
  }
};
