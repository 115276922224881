import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import { resetForm } from 'store/state/search/common.actions';

interface ClearProps {
  onClear: () => void;
}

const Clear = (props: ClearProps) => (
  <button className="btn btn-lg btn-secondary" onClick={props.onClear}>
    Clear
  </button>
);

const mapStateToProps = (_: AppState) => ({});
const dispatchProps = {
  onClear: resetForm,
};

export const ClearConnected = connect(mapStateToProps, dispatchProps)(Clear);
