import { AlgoStreamSearchRow } from 'model/algo-stream';
import { Client, ClientOverview } from 'model/client';
import { DistributionStreamSearchRow } from 'model/distribution-stream';

export const displayBdrIdsMassUpload = () => ({ type: 'SearchResult.displayBdrIdsMassUpload' } as const);

export const singleClientSearchSuccess = (client: Client) =>
  ({
    type: 'SearchResult.singleClientSearchSuccess',
    client,
  } as const);

export const clientSearchSuccess = (clients: readonly ClientOverview[]) =>
  ({
    type: 'SearchResult.clientSearchSuccess',
    clients,
  } as const);

export const algoStreamSearchSuccess = (algoStreams: readonly AlgoStreamSearchRow[]) =>
  ({
    type: 'SearchResult.algoStreamSearchSuccess',
    algoStreams,
  } as const);

export const distributionStreamSearchSuccess = (
  distributionStreams: readonly DistributionStreamSearchRow[],
  searched: string,
) =>
  ({
    type: 'SearchResult.distributionStreamSearchSuccess',
    distributionStreams,
    searched,
  } as const);

export const clientSearchError = (message: string) =>
  ({
    type: 'SearchResult.clientSearchError',
    message,
  } as const);
