import { DistributionStream } from 'model/distribution-stream';
import { ColumnName, TableName } from 'model/entity';
import React, { useCallback, useState } from 'react';
import { MarginCategoryRuleTable } from './margin-category';
import { PublishedBucketsRuleTable } from './published-bucket-rule';
import { StreamAliasRuleTable } from './stream-alias-rule';
import { TradingGroupRuleTable } from './trading-group';

interface DistributionStreamComponentProps {
  id: number;
  value: DistributionStream;
  editing: boolean;
  products: readonly string[];
  tenors: readonly string[];
  marginCategories: readonly string[];
  tradingGroups: readonly string[];
  ecns: readonly string[];
  algoTypes: readonly string[];
  currencies: readonly string[];
  onChange<T extends TableName<DistributionStream>>(
    tableName: T,
    columnName: ColumnName<DistributionStream[T][0]>,
    rowId: number,
    value: string,
  ): void;
  deleteLine(tableName: TableName<DistributionStream>, rowId: number): void;
  addLine(tableName: TableName<DistributionStream>, afterLine?: number): void;
}

function Tab({ name, displayed, clicked }: { name: string; displayed: boolean; clicked: () => void }) {
  return (
    <li className="nav-item">
      <a className={`nav-link ${displayed ? 'active' : ''}`} href="#" onClick={clicked}>
        {name}
      </a>
    </li>
  );
}
export function DistributionStreamComponent(props: DistributionStreamComponentProps) {
  const [displayed, setDisplayed] = useState<TableName<DistributionStream>>('tradingGroupRule');
  const goToTradingGroup = useCallback(() => setDisplayed('tradingGroupRule'), [setDisplayed]);
  const goToMargin = useCallback(() => setDisplayed('marginCategoryRule'), [setDisplayed]);
  const goToStreamAlias = useCallback(() => setDisplayed('streamAliasRule'), [setDisplayed]);
  const goToPublished = useCallback(() => setDisplayed('publishedBucketsRule'), [setDisplayed]);

  return (
    <div>
      <ul className="nav nav-tabs">
        <Tab name="Trading Group" displayed={displayed === 'tradingGroupRule'} clicked={goToTradingGroup} />
        <Tab name="Margin Category" displayed={displayed === 'marginCategoryRule'} clicked={goToMargin} />
        <Tab name="Stream Alias" displayed={displayed === 'streamAliasRule'} clicked={goToStreamAlias} />
        <Tab name="Published Bucket" displayed={displayed === 'publishedBucketsRule'} clicked={goToPublished} />
      </ul>
      <div>
        <DisplayedGrid
          displayedTable={displayed}
          editing={props.editing}
          tenors={props.tenors}
          marginCategories={props.marginCategories}
          products={props.products}
          value={props.value}
          onChange={props.onChange}
          deleteLine={props.deleteLine}
          addLine={props.addLine}
          tradingGroups={props.tradingGroups}
          algoTypes={props.algoTypes}
          ecns={props.ecns}
          currencies={props.currencies}
        />
      </div>
    </div>
  );
}

interface DisplayedGrid {
  displayedTable: TableName<DistributionStream>;
  editing: boolean;
  marginCategories: readonly string[];
  products: readonly string[];
  tenors: readonly string[];
  tradingGroups: readonly string[];
  ecns: readonly string[];
  algoTypes: readonly string[];
  currencies: readonly string[];
  value: DistributionStream;
  // validation: ValidationOf<DistributionStream>;
  onChange<T extends TableName<DistributionStream>>(
    tableName: T,
    columnName: ColumnName<DistributionStream[T][0]>,
    rowId: number,
    value: string,
  ): void;
  deleteLine(tableName: TableName<DistributionStream>, rowId: number): void;
  addLine(tableName: TableName<DistributionStream>, index?: number): void;
}

function useOnChange<T extends TableName<DistributionStream>>(
  onChange: (tableName: T, columnName: ColumnName<DistributionStream[T][0]>, rowId: number, value: string) => void,
  table: T,
) {
  return useCallback(
    (columnName: ColumnName<DistributionStream[T][0]>, rowId: number, value: string) =>
      onChange(table, columnName, rowId, value),
    [table, onChange],
  );
}

const useDeleteLine = (
  deleteLine: (tableName: TableName<DistributionStream>, rowId: number) => void,
  table: TableName<DistributionStream>,
) => useCallback((rowId: number) => deleteLine(table, rowId), [table, deleteLine]);

const useAddlLine = (
  addLine: (tableName: TableName<DistributionStream>, index?: number) => void,
  table: TableName<DistributionStream>,
) => useCallback((index?: number) => addLine(table, index), [table, addLine]);

function DisplayedGrid(props: DisplayedGrid) {
  return (
    <>
      <MarginCategoryRuleTable
        editing={props.editing}
        marginCategories={props.marginCategories}
        tenors={props.tenors}
        products={props.products}
        values={props.value.marginCategoryRule}
        addLine={useAddlLine(props.addLine, 'marginCategoryRule')}
        onChange={useOnChange(props.onChange, 'marginCategoryRule')}
        deleteLine={useDeleteLine(props.deleteLine, 'marginCategoryRule')}
        currencies={props.currencies}
        display={props.displayedTable === 'marginCategoryRule'}
      />
      <TradingGroupRuleTable
        editing={props.editing}
        addLine={useAddlLine(props.addLine, 'tradingGroupRule')}
        deleteLine={useDeleteLine(props.deleteLine, 'tradingGroupRule')}
        onChange={useOnChange(props.onChange, 'tradingGroupRule')}
        products={props.products}
        tenors={props.tenors}
        tradingGroups={props.tradingGroups}
        values={props.value.tradingGroupRule}
        currencies={props.currencies}
        display={props.displayedTable === 'tradingGroupRule'}
      />
      <StreamAliasRuleTable
        editing={props.editing}
        addLine={useAddlLine(props.addLine, 'streamAliasRule')}
        deleteLine={useDeleteLine(props.deleteLine, 'streamAliasRule')}
        onChange={useOnChange(props.onChange, 'streamAliasRule')}
        ecns={props.ecns}
        values={props.value.streamAliasRule}
        display={props.displayedTable === 'streamAliasRule'}
      />
      <PublishedBucketsRuleTable
        editing={props.editing}
        addLine={useAddlLine(props.addLine, 'publishedBucketsRule')}
        deleteLine={useDeleteLine(props.deleteLine, 'publishedBucketsRule')}
        onChange={useOnChange(props.onChange, 'publishedBucketsRule')}
        ecns={props.ecns}
        values={props.value.publishedBucketsRule}
        algoTypes={props.algoTypes}
        display={props.displayedTable === 'publishedBucketsRule'}
        currencies={props.currencies}
      />
    </>
  );
}
