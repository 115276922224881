import React, { ChangeEvent, memo, useCallback, useRef } from 'react';
import { usePreventCallback } from './event-handler-helpers';
import { createValidatedOnChange, Validator } from './validators';

export const LimitedEditable = memo(function LimitedEditable_({
  defaultValue,
  editing,
  value,
  possibleValues,
  onChange,
  validate,
  disabled,
}: {
  defaultValue?: { label: string; value: string } | string;
  editing: boolean;
  value: string;
  validate?: Validator;
  possibleValues: readonly string[];
  onChange: (s: string) => void;
  disabled?: boolean;
}) {
  const trueDefaultValue = defaultValue ?? possibleValues[0];

  const defaultValueLabel = typeof trueDefaultValue === 'object' ? trueDefaultValue.label : trueDefaultValue;
  const defaultValueValue = typeof trueDefaultValue === 'object' ? trueDefaultValue.value : trueDefaultValue;

  value = value || defaultValueLabel;
  value = value || defaultValueValue;

  const element = useRef(null as unknown as HTMLSelectElement);

  const [doOnChange, error] = createValidatedOnChange(element, onChange, validate, defaultValueValue);

  return (
    <>
      {editing ? (
        <>
          <select ref={element} disabled={disabled} className="form-control" value={value} onChange={doOnChange}>
            {defaultValue !== undefined && <option value={defaultValueValue}>{defaultValueLabel}</option>}
            {(possibleValues || []).map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
          <span className="invalid-tooltip">{error}</span>
        </>
      ) : (
        value
      )}
    </>
  );
});
interface EditableProps {
  disabled?: boolean;
  editing: boolean;
  value: string;
  onChange: (s: string) => void;
  validate?: Validator;
  defaultValue?: string;
}

export const Editable = memo(function Editable_(props: EditableProps) {
  const element = useRef(null as unknown as HTMLInputElement);
  const [doOnChange, error] = createValidatedOnChange(element, props.onChange, props.validate, props.defaultValue);
  return (
    <>
      <input
        required
        ref={element}
        disabled={props.disabled}
        className={`form-control ${props.editing ? '' : 'd-none'}`}
        onChange={doOnChange}
        defaultValue={props.value || props.defaultValue}
      />
      <span className="invalid-tooltip">{error}</span>

      <span className={props.editing ? 'd-none' : ''}>{props.value || props.defaultValue}</span>
    </>
  );
});

export function EditableButton({
  editing,
  value,
  onClick,
  onChange,
  validate,
}: {
  editing: boolean;
  value: string;
  onClick?: () => void;
  onChange: (s: string) => void;
  validate?: Validator;
}) {
  const doOnClick = usePreventCallback((c) => onClick && onClick(), [onClick]);
  const input = useRef<HTMLInputElement>(null);
  const [doOnChange, error] = createValidatedOnChange(input, onChange, validate, undefined);

  return (
    <>
      {editing ? (
        <>
          <input ref={input} className="form-control" value={value} onChange={doOnChange} required />
          <span className="invalid-tooltip">{error}</span>
        </>
      ) : (
        <button className="btn editable-button btn-link w-100" onClick={doOnClick}>
          {value}
        </button>
      )}
    </>
  );
}

export const TenorEditable = memo(function TenorEditable_(props: {
  disabled?: boolean;
  possibleValues: readonly string[];
  value: string;
  editing: boolean;
  onChange: (s: string) => void;
}) {
  const [v1, v2] = props.value === undefined || props.value === null ? ['*', undefined] : props.value.split('_');
  const changedLeft = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => props.onChange(toTenorPair(e.target.value, v2)),
    [props.onChange, props.value],
  );
  const changedRight = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => props.onChange(toTenorPair(v1, e.target.value)),
    [props.onChange, props.value],
  );

  const begin = props.possibleValues.findIndex((c) => c === v1);
  const end = props.possibleValues.findIndex((c) => c === v2);
  const afterBegin = begin === -1 ? props.possibleValues : props.possibleValues.slice(begin + 1);
  const beforeEnd = end === -1 ? props.possibleValues : props.possibleValues.slice(0, end);

  switch (props.editing) {
    case true:
      return (
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">&gt;</span>{' '}
          </div>
          <select disabled={props.disabled} className="form-control" value={v1 || '*'} onChange={changedLeft}>
            <option value="*">*</option>
            {(beforeEnd || []).map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
          <div>
            <span className="input-group-text">&amp; &le;</span>{' '}
          </div>

          <select disabled={props.disabled} className="form-control" value={v2 || '*'} onChange={changedRight}>
            <option value="*">*</option>
            {(afterBegin || []).map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
        </div>
      );
    case false:
      if (v2 === undefined) {
        return <>{v1}</>;
      }
      return <TenorPair from={v1} to={v2} />;
  }
});

const toTenorPair = (t1: string, t2: string | undefined) => {
  if (t1 === '*' && (t2 === '*' || t2 === '' || t2 === undefined)) {
    return '*';
  }

  return `${t1 || '*'}_${t2 || '*'}`;
};

type TenorPairProps = { from: string; to: string } | { pair: string };
export function TenorPair(props: TenorPairProps) {
  let from: string;
  let to: string | undefined;

  if ('pair' in props) {
    [from, to] = props.pair === undefined || props.pair === null ? ['*', undefined] : props.pair.split('_');
  } else {
    from = props.from;
    to = props.to;
  }
  if (to === undefined) {
    return <>{from}</>;
  }
  return (
    <>
      &gt; {from} &amp; &le; {to}
    </>
  );
}

export function BidAskEditable(props: {
  disabled?: boolean;
  possibleValues: readonly string[];
  value: string;
  editing: boolean;
  onChange: (s: string | undefined) => void;
}) {
  const [v1, v2] = props.value === undefined || props.value === null ? [undefined, undefined] : props.value.split('/');
  const changedLeft = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => props.onChange(toBidAsk(e.target.value, v2)),
    [props.onChange, props.value],
  );
  const changedRight = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => props.onChange(toBidAsk(v1, e.target.value)),
    [props.onChange, props.value],
  );

  switch (props.editing) {
    case true:
      return (
        <div className="input-group">
          <input
            disabled={props.disabled}
            className="form-control without-spinner"
            value={v1 || ''}
            onChange={changedLeft}
          />
          <span className="input-group-text">/</span>
          <input
            disabled={props.disabled}
            className="form-control without-spinner"
            value={v2 || ''}
            onChange={changedRight}
          />
        </div>
      );
    case false:
      return <>{props.value || 'REJECTED'}</>;
  }
}

function toBidAsk(x: string | undefined, y: string | undefined) {
  if ((x === '*' || x === '' || x === undefined) && (y === '*' || y === '' || y === undefined)) {
    return undefined;
  }
  return `${x || ''}/${y || ''}`;
}
