import * as React from 'react';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['sgwt-mini-footer']: any;
    }
  }
}

export function Footer() {
  return (
    <sgwt-mini-footer
      container="container-fluid"
      mode="sg-markets"
      contact-us={JSON.stringify({
        mail: 'alphafxbullion.world@sgcib.com',
        phones: [
          { label: 'emea', value: '+33 (0)1 42 13 86 00' },
          { label: 'americas', value: '+1 212 278 6022' },
          { label: 'asia pacific', value: '+852 2166 5707' },
          { label: 'japan', value: '+81 3 5549 5445' },
        ],
      })}
      accessibility-compliance="none"
      accessibility-link="https://shared.sgmarkets.com/accessibility/statement/en.html?redirect_uri=https://sgme-act.fr.world.socgen"
    />
  );
}
