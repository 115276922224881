import React from 'react';
import { Card } from '../utils/card';
import { ClientSearchByNameFormConnected } from './client-search-by-name-form';
import { ClientSearchByAlgoNameFormConnected } from './client-search-by-stream-name-form';

export function ClientSearchForm() {
  return (
    <Card title={<h4>Client</h4>}>
      <ClientSearchByNameFormConnected />
      <ClientSearchByAlgoNameFormConnected />
    </Card>
  );
}
