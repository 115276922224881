import { Icon } from 'app/utils/icons';
import React from 'react';

export function EditMenuCell(props: { addLine: () => void; deleteLine: () => void }) {
  return (
    <td className="editable-table-edit-menu">
      <Icon type="add" onClick={props.addLine} color="info" />
      <Icon type="close" onClick={props.deleteLine} color="danger" />
    </td>
  );
}
