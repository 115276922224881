import { Entity, EntityTableName, ofNotCreated } from 'model/entity';
import { Referentials } from 'model/referentials';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import {
  addLine,
  askConfirm,
  cancelConfirm,
  cancelEdit,
  deleteLine,
  duplicateDistributionStream,
  editEntity,
  editFormChanged,
  fetchAlgoStreamDetails,
  fetchDistributionStreamDetails,
  removeEntity,
  saveEntity,
  updateTitle,
  validating,
} from 'store/state/entity-list/entity-list.actions';

import { addAlert } from 'store/state/alerts/alerts.actions';
import { EntityContent } from './content/entity-content';
import { EntityPanel } from './entity-panel';

export const MassUploadContext = React.createContext<string>('');

interface EntityComponentProps {
  value: Entity;
  isAdmin: boolean;
  askConfirm: (id: number) => void;
  removeEntity: (id: number) => void;
  fetchDistributionStreamDetails: (name: string) => void;
  fetchAlgoStreamDetails: (name: string) => void;
  editEntity: (id: number) => void;
  duplicateDistributionStream: (id: number) => void;
  referentials: Referentials;
  bdrIdsMassUpload: string;
  editFormChanged: (
    entityId: number,
    tableName: EntityTableName,
    columnName: string,
    rowId: number,
    value: string,
  ) => void;
  deleteLine: (entityId: number, table: EntityTableName, line: number) => void;
  saveEntity: (entityId: number) => void;
  addLine: (entityId: number, table: EntityTableName, afterIndex?: number) => void;
  cancelEdit: (entityId: number) => void;
  updateTitle: (id: number, title: string) => void;
  cancelConfirm: (entityId: number) => void;
  validating: (entityId: number) => void;
  alert: (message: string) => void;
}

const EntityComponent = (props: EntityComponentProps) => {
  const doCancelEdit = useCallback(() => props.cancelEdit(props.value.id), [props.value.id, props.cancelEdit]);
  const doRemoveEntity = useCallback(() => props.removeEntity(props.value.id), [props.value.id, props.removeEntity]);
  const doEdit = useCallback(() => props.editEntity(props.value.id), [props.value.id, props.editEntity]);
  const doSave = useCallback(() => props.saveEntity(props.value.id), [props.value.id, props.saveEntity]);
  const doOnAskConfirm = useCallback(() => props.askConfirm(props.value.id), [props.value.id, props.askConfirm]);
  const doOnCancelConfirm = useCallback(
    () => props.cancelConfirm(props.value.id),
    [props.value.id, props.cancelConfirm],
  );
  const doOnValidating = useCallback(() => props.validating(props.value.id), [props.value.id, props.validating]);
  const doUpdateTitle = useCallback(
    (t: string) => props.updateTitle(props.value.id, t),
    [props.value.id, props.updateTitle],
  );

  const doDuplicate = useCallback(() => {
    if (props.value.type === 'distribution-stream') {
      props.duplicateDistributionStream(props.value.id);
    }
  }, [props.value.id, props.duplicateDistributionStream]);
  return (
    <MassUploadContext.Provider value={props.bdrIdsMassUpload}>
      <EntityPanel
        value={props.value}
        onTitleChange={doUpdateTitle}
        onClose={doRemoveEntity}
        onEdit={doEdit}
        onDuplicate={doDuplicate}
        onSave={doSave}
        editting={props.value.state !== 'reading'}
        isAdmin={props.isAdmin}
        onAskConfirm={doOnAskConfirm}
        onCancelConfirm={doOnCancelConfirm}
        onValidating={doOnValidating}
        alert={props.alert}
      >
        <EntityContent
          addLine={props.addLine}
          deleteLine={props.deleteLine}
          editFormChanged={props.editFormChanged}
          fetchAlgoStreamDetails={props.fetchAlgoStreamDetails}
          fetchDistributionStreamDetails={props.fetchDistributionStreamDetails}
          referentials={props.referentials}
          value={props.value}
        />
        {props.value.state !== 'reading' && !ofNotCreated(props.value) && (
          <button className="btn btn-secondary" onClick={doCancelEdit}>
            Cancel
          </button>
        )}
      </EntityPanel>
    </MassUploadContext.Provider>
  );
};

const mapStateToProps = (state: AppState) => ({
  referentials: state.referentials.values,
  bdrIdsMassUpload: state.massiveUploadForm.bdrIds,
});

const mapDispatchToProps = {
  removeEntity,
  fetchDistributionStreamDetails,
  fetchAlgoStreamDetails,
  editEntity,
  duplicateDistributionStream,
  editFormChanged,
  saveEntity,
  deleteLine,
  addLine,
  cancelEdit,
  updateTitle,
  askConfirm,
  cancelConfirm,
  validating,
  alert: addAlert,
};

export const EntityComponentConnected = connect(mapStateToProps, mapDispatchToProps)(EntityComponent);
