export const bdrInputChanged = (bdrId: string) =>
  ({
    type: 'SearchForm.Client.bdrInputChanged',
    bdrId,
  } as const);

export const formSubmitted = () =>
  ({
    type: 'SearchForm.Client.formSubmitted',
  } as const);
