import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import { bdrInputChanged, formSubmitted } from 'store/state/search/client-search-form/client-search-form.actions';
import { SearchForm } from './search-form';

interface BdrIdSearchFormProps {
  bdrId: string;
  bdrInputChanged: (bdrId: string) => void;
  formSubmitted: () => void;
}

const ClientSearchByNameForm = (props: BdrIdSearchFormProps) => (
  <SearchForm
    title="Bdr Id"
    formSubmitted={props.formSubmitted}
    valueChanged={props.bdrInputChanged}
    value={props.bdrId}
  />
);

const mapStateToProps = (state: AppState) => ({
  bdrId: state.clientSearchForm.bdrId,
});

const mapDispatchToProps = {
  bdrInputChanged,
  formSubmitted,
};

export const ClientSearchByNameFormConnected = connect(mapStateToProps, mapDispatchToProps)(ClientSearchByNameForm);
