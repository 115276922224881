import React, { FormEvent, useCallback } from 'react';
import { preventAll } from './event-handler-helpers';

// more exists, but not used yet
type IconType = 'edit' | 'close' | 'save' | 'add' | 'content_copy';
export type Color = 'danger' | 'info' | 'success' | 'warning';
export function Icon({ type, onClick, color }: { type: IconType; onClick?: () => void; color?: Color }) {
  return (
    <i
      className={`clickable icon icon-sm ${color ? 'text-' + color : ''}`}
      onClick={useCallback(() => onClick && onClick(), [onClick])}
    >
      {type}
    </i>
  );
}

const titles: Record<IconType, string> = {
  add: 'Add',
  edit: 'Edit',
  close: 'Close',
  save: 'Save',
  content_copy: 'Duplicate',
};

export function ButtonIcon({ type, onClick }: { type: IconType; onClick?: (e: FormEvent<HTMLButtonElement>) => void }) {
  return (
    <button
      className="btn btn-secondary ml-1 clickable"
      onClick={useCallback((e: FormEvent<HTMLButtonElement>) => onClick && onClick(e), [onClick])}
      title={titles[type]}
    >
      <i className="icon icon-sm">{type}</i>
    </button>
  );
}

export function SubmitButtonIcon({ type }: { type: IconType }) {
  return (
    <input type="submit" className="icon icon-sm btn btn-secondary ml-1 clickable" value={type} onClick={preventAll} />
  );
}
