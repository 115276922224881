import { lazyApply } from 'app/utils/event-handler-helpers';
import { Client, ClientOverview } from 'model/client';
import { DistributionStreamSearchRow } from 'model/distribution-stream';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import {
  fetchAlgoStreamDetails,
  fetchClientDetails,
  fetchDistributionStreamDetails,
} from 'store/state/entity-list/entity-list.actions';
import { updateMassUploadBdrIds } from 'store/state/massive-upload/massive-upload.actions';
import { SearchResultState } from 'store/state/search-result/search-result.reducer';

interface SearchResultGridProps {
  readonly result: SearchResultState;
  readonly massUploadForm: string | undefined;
  readonly fetchClientDetails: (client: Client | ClientOverview) => void;
  readonly fetchAlgoStreamDetails: (name: string) => void;
  readonly updateMassUploadBdrIds: (bdrIds: string) => void;

  readonly fetchDistributionStreamDetails: (name: string) => void;
}

export function SearchResultGrid(props: SearchResultGridProps) {
  switch (props.result.type) {
    case 'init':
      return <></>;
    case 'error':
      return <div>error : {props.result.message}</div>;
    case 'single-client-success':
      return (
        <div>
          <table className="table table-hover table-clickable">
            <thead>
              <tr>
                <th>Bdr ID</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr onDoubleClick={lazyApply(props.fetchClientDetails, props.result.client)}>
                <td>{props.result.client.bdrId}</td>
                <td>{props.result.client.name}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    case 'client-success':
      return (
        <div>
          <table className="table table-hover table-clickable">
            <thead>
              <tr>
                <th>Bdr ID</th>
                <th>Name</th>
              </tr>
              {props.result.values.length === 2000 && (
                <tr className="table-warning">
                  <td colSpan={2}>Limit of 2000 results displayed. More may exists</td>
                </tr>
              )}
            </thead>
            <tbody>
              {props.result.values.map((client) => (
                <tr key={client.id} onDoubleClick={lazyApply(props.fetchClientDetails, client)}>
                  <td>{client.bdrId}</td>
                  <td>{client.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );

    case 'algo-stream-success':
      return (
        <div>
          <table className="table table-hover table-clickable">
            <thead>
              <tr>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {props.result.values.map((algoStream) => (
                <tr key={algoStream.id} onDoubleClick={lazyApply(props.fetchAlgoStreamDetails, algoStream.name)}>
                  <td>{algoStream.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    case 'display-bdrids-mass-upload':
      return (
        <div>
          <table className="table table-hover table-clickable">
            <thead>
              <tr>
                <th>BdrIds (with separator ';')</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <textarea
                    style={{
                      width: '100%',
                      height: '200px',
                      marginTop: '10px',
                    }}
                    onChange={(evt) => {
                      props.updateMassUploadBdrIds(evt.target.value);
                    }}
                    value={props.massUploadForm}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    case 'distribution-stream-success':
      return (
        <div>
          <table className="table table-hover table-clickable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Match</th>
              </tr>
            </thead>
            <tbody>
              {props.result.values.map((distributionStream) => (
                <DistributionStreamSearchTableRow
                  key={distributionStream.id}
                  searched={props.result.type === 'distribution-stream-success' ? props.result.searched : ''}
                  distributionStream={distributionStream}
                  fetchDistributionStreamDetails={props.fetchDistributionStreamDetails}
                />
              ))}
            </tbody>
          </table>
        </div>
      );
    case 'no-result':
      return <div>nothing was found</div>;
  }
}

const DistributionStreamSearchTableRow = (props: {
  searched: string;
  distributionStream: DistributionStreamSearchRow;
  fetchDistributionStreamDetails: (name: string) => void;
}) => {
  const doOnClick = useCallback(
    () => props.fetchDistributionStreamDetails(props.distributionStream.name),
    [props.fetchDistributionStreamDetails, props.distributionStream.name],
  );

  return (
    <tr key={props.distributionStream.id} onDoubleClick={doOnClick}>
      <td>
        <Hightligh part={props.searched}>{props.distributionStream.name}</Hightligh>
      </td>
      <td>
        {props.distributionStream.matches
          .map(({ ecn, alias }) => (
            <>
              <Hightligh key={`${ecn}-${alias}`} part={props.searched}>
                {`${ecn} alias ${alias}`}
              </Hightligh>
            </>
          ))
          .reduce((acc, next) => {
            if (acc.length !== 0) {
              acc.push(', ');
            }
            acc.push(next);
            return acc;
          }, [] as Array<JSX.Element | string>)}
      </td>
    </tr>
  );
};

const Hightligh = (props: { children: string; part: string }) => {
  if (props.children.toLowerCase().indexOf(props.part.toLowerCase()) === -1) {
    return <>{props.children}</>;
  }
  const nameArr = props.children.match(
    new RegExp(`(.*)(${props.part.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')})(.*)`, 'i'),
  )!;
  console.log(nameArr, props.children, `(.*)(${props.part})(.*)`);
  return (
    <>
      {nameArr[1]}
      <strong>{nameArr[2]}</strong>
      {nameArr[3]}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  result: state.searchResult,
  massUploadForm: state.massiveUploadForm.bdrIds,
});

const mapDispatchToProps = {
  fetchClientDetails,
  fetchAlgoStreamDetails,
  fetchDistributionStreamDetails,
  updateMassUploadBdrIds,
};

export const SearchResultGridConnected = connect(mapStateToProps, mapDispatchToProps)(SearchResultGrid);
