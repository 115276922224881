import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import { AppAction } from './app.actions';
import { appEpic } from './app.epic';
import { appReducer } from './app.reducer';
import { AppState } from './app.state';

export function createAppStore() {
  const epicMiddleware = createEpicMiddleware<AppAction, AppAction, AppState>();

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(appReducer, composeEnhancers(applyMiddleware(epicMiddleware)));
  epicMiddleware.run(appEpic);
  return store;
}
