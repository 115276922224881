import { BaseSyntheticEvent, DependencyList, FormEvent, useCallback } from 'react';

export const withPreventDefault = (f: () => void) => (e: FormEvent<unknown>) => {
  f();
  e.preventDefault();
};

export const passTargetValueTo =
  <T>(f: (s: T) => void) =>
  (e: { target: { value: T } }) => {
    f(e.target.value);
  };

export const lazyApply =
  <T>(f: (t: T) => void, t: T) =>
  () =>
    f(t);
export const lazyApply2 =
  <T, K>(f: (t: T, k: K) => void, t: T, k: K) =>
  () =>
    f(t, k);

export function usePreventCallback<T>(
  callback: (args: FormEvent<T>) => any,
  deps: DependencyList = [],
): (args: FormEvent<T>) => void {
  return useCallback((e: FormEvent<T>) => {
    preventAll(e);
    return callback(e);
  }, deps);
}
export function useTargetValueCallback<T>(
  callback: (args: T) => any,
  deps: DependencyList = [],
): (args: { target: { value: T } }) => any {
  return useCallback((e: { target: { value: T } }) => callback(e.target.value), deps);
}

export function preventAll(e: BaseSyntheticEvent) {
  e.preventDefault();
  e.stopPropagation();
}
