import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import {
  algoStreamFormSubmitted,
  algoStreamNameChanged,
} from 'store/state/search/algo-stream-search-form/algo-stream-search-form.actions';
import { Card } from '../utils/card';
import { SearchForm } from './search-form';

interface AlgoStreamSearchFormProps {
  name: string;
  nameChanged: (bdrId: string) => void;
  formSubmitted: () => void;
}

const AlgoStreamSearchForm = (props: AlgoStreamSearchFormProps) => (
  <Card title={<h4>Algo Stream</h4>}>
    <SearchForm title="Name" formSubmitted={props.formSubmitted} valueChanged={props.nameChanged} value={props.name} />
  </Card>
);

const mapStateToProps = (state: AppState) => ({
  name: state.algoStreamSearchForm.name,
});

const mapDispatchToProps = {
  nameChanged: algoStreamNameChanged,
  formSubmitted: algoStreamFormSubmitted,
};

export const AlgoStreamSearchFormConnected = connect(mapStateToProps, mapDispatchToProps)(AlgoStreamSearchForm);
