import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import {
  distributionStreamFormSubmitted,
  distributionStreamNameChanged,
} from 'store/state/search/distribution-stream-search-form/distribution-stream-search-form.actions';
import { Card } from '../utils/card';
import { SearchForm } from './search-form';

interface DistributionStreamSearchFormProps {
  name: string;
  nameChanged: (bdrId: string) => void;
  formSubmitted: () => void;
}

const DistributionStreamSearchForm = (props: DistributionStreamSearchFormProps) => (
  <Card title={<h4>Distribution Stream</h4>}>
    <SearchForm formSubmitted={props.formSubmitted} title="Name" value={props.name} valueChanged={props.nameChanged} />
  </Card>
);

const mapStateToProps = (state: AppState) => ({
  name: state.distributionStreamSearchForm.name,
});

const mapDispatchToProps = {
  nameChanged: distributionStreamNameChanged,
  formSubmitted: distributionStreamFormSubmitted,
};

export const DistributionStreamSearchFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DistributionStreamSearchForm);
