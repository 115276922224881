import { EditMenuCell } from 'app/entity-list/content/util/TableEditMenu';
import React, { ReactNode } from 'react';
import { RowOf, Table } from './table';

interface EditableTableProps<T> {
  editing: boolean;
  children: (t: RowOf<T>, index: number, onlyResult?: boolean) => ReactNode;
  rowId: keyof T;
  resultKey: keyof T;
  values: Table<T>;
  size: number;
  defaultResult?: string;
  useOtherConditionGet: boolean;
  deleteLine: (index: number) => void;
  addLine: (index: number) => void;
}

export function EditableTable<K extends string, T extends { [key in K]?: string | number }>(
  props: EditableTableProps<T>,
) {
  return props.values === undefined ? null : (
    <>
      {props.values.values.map((value, index) => (
        <tr key={value[props.rowId].value}>
          {props.children(value, index, false)}
          {props.editing && (
            <EditMenuCell deleteLine={() => props.deleteLine(index)} addLine={() => props.addLine(index)} />
          )}
        </tr>
      ))}
      {props.values.end !== undefined && (
        <>
          {props.useOtherConditionGet && (
            <tr key="separator">
              <td colSpan={props.size}>
                <br />
              </td>
            </tr>
          )}
          {props.editing || !props.useOtherConditionGet ? (
            <tr key={props.values.end[props.rowId].value}>
              {props.children(props.values.end, props.values.values.length, props.useOtherConditionGet)}
              {props.editing && !props.useOtherConditionGet && (
                <EditMenuCell
                  deleteLine={() => props.deleteLine(props.values.values.length)}
                  addLine={() => props.addLine(props.values.values.length)}
                />
              )}
            </tr>
          ) : (
            <tr key={props.values.end[props.rowId].value}>
              <td className="condition-get" colSpan={props.size - 1}>
                ALL OTHER CONDITIONS GET
              </td>
              <td className="result-column">{props.values.end[props.resultKey].value || props.defaultResult}</td>
            </tr>
          )}
        </>
      )}
    </>
  );
}
export function Cell(props: {
  editing: boolean;
  length: number;
  displayed: boolean;
  children: ReactNode;
  result?: boolean;
}) {
  return props.editing || props.displayed ? (
    <td
      style={{ position: 'relative' }}
      className={`editable-cell ${props.result ? 'result-column' : ''}`}
      rowSpan={props.editing ? 1 : props.length}
    >
      {props.children}
    </td>
  ) : (
    <></>
  );
}
