import { AppAction } from 'store/app.actions';

interface AlgoStreamSearchFormState {
  readonly name: string;
}

const initialState: AlgoStreamSearchFormState = {
  name: '',
};

export const algoStreamSearchReducer = (state = initialState, action: AppAction): AlgoStreamSearchFormState => {
  switch (action.type) {
    case 'SearchForm.AlgoStream.nameChanged':
      return { ...state, name: action.name };

    case 'SearchForms.reset':
      return { ...state, name: initialState.name };

    default:
      return state;
  }
};
