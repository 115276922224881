import {
  createValidatedOnChange,
  isRequiredValidator,
  useAllValidator,
  useInvalidCharactersValidator,
  useMustNotBeAlgoNameValidator,
  useMustNotBeDistNameValidator,
} from 'app/utils/validators';
import { Entity, ofNotCreated } from 'model/entity';
import { useRef } from 'react';
import React from 'react';

interface EntityTitleProps {
  value: Entity;
  onChange: (e: string) => void;
}

export function EntityTitle({ value, onChange }: EntityTitleProps) {
  const ref = useRef(null as unknown as HTMLInputElement);
  const distValidator = useMustNotBeDistNameValidator();
  const algoValidator = useMustNotBeAlgoNameValidator();
  const [doOnChange, error] = createValidatedOnChange(
    ref,
    onChange,
    useAllValidator(
      useInvalidCharactersValidator(['<', '>', '&', '\n', ',']),
      value.type === 'distribution-stream' ? distValidator : algoValidator,
      isRequiredValidator,
    ),
    undefined,
  );
  if (ofNotCreated(value) && value.type !== 'client' && value.type !== 'mass-upload') {
    return (
      <>
        <input
          type="text"
          className="form-control"
          placeholder="Insert title here..."
          onChange={doOnChange}
          ref={ref}
        />
        <span className="invalid-tooltip">{error}</span>
      </>
    );
  }

  return <h4>{value.value.name}</h4>;
}
