export interface AlgoStreamSearchRow {
  id: number;
  name: string;
}

export interface AlgoStream {
  id?: number;
  name: string;
  algoFeesRules: readonly AlgoFeeRule[];
}

export interface AlgoFeeRule {
  id?: number;
  algoType: string;
  internalExternal: string;
  clientFees: string;
  currencyGroup: string;
}

export const newAlgoStream = (): AlgoStream => ({
  id: undefined,
  name: '',
  algoFeesRules: [{ id: 0, algoType: '*', internalExternal: '*', clientFees: 'REJECTED', currencyGroup: '*' }],
});

export const createAlgoLine = (id: number) => ({
  id,
  algoType: '*',
  internalExternal: '*',
  clientFees: '',
});
