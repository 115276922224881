import { setupSGWTConnect, SGWTConnectCore, ISGWTConnectConfiguration } from '@sgwt/connect-core';

declare global {
  interface Window {
    sgwtConnect: SGWTConnectCore;
    sgmeConfiguration: AppConfiguration;
    SGWTWidgetConfiguration: { environment: string };
  }
}
interface AppConfiguration {
  env: string; // 'local' | 'dev-ci' | 'dev-int' | 'uat-rc' | 'production' | 'test'
  fake_user: string;
  api_url: string;
  sgconnect: ISGWTConnectConfiguration;
  redirect_uri: string;
}

export function getConfig(): AppConfiguration {
  return window.sgmeConfiguration;
}

const createFake = () =>
  ({
    getAuthorizationHeader() {
      return 'Fake test';
    },
  } as SGWTConnectCore);

const appConfig = window.sgmeConfiguration;

export const sgwtConnect = appConfig.env === 'test' ? createFake() : setupSGWTConnect(appConfig.sgconnect);

export const fakeUser: string | undefined = getFakeUserFromUrl() || appConfig.fake_user || undefined;

window.sgwtConnect = sgwtConnect;

function getFakeUserFromUrl() {
  const userParam = getUrlParam('user');
  const isEmailValid = userParam.match(/[a-z]+\.[a-z]+@[a-z]+\.[a-z]+/gi);
  return isEmailValid ? userParam : '';
}

// https://developer.mozilla.org/fr/docs/Web/API/window/location
function getUrlParam(name: string) {
  return unescape(
    window.location.search.replace(
      new RegExp('^(?:.*[&\\?]' + escape(name).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'),
      '$1',
    ),
  );
}
