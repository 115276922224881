import { preventAll } from 'app/utils/event-handler-helpers';
import { Color } from 'app/utils/icons';
import React, { createContext, useContext } from 'react';

const ParentCollaspeId = createContext('');

export function CollaspeList({ id, children }: { id: string; children: React.ReactNode }) {
  return (
    <ParentCollaspeId.Provider value={id}>
      <div className={`sgbs-collapse-separator ${id}`}>{children}</div>
    </ParentCollaspeId.Provider>
  );
}

export function Collapse({
  index,
  title,
  children,
  show,
  color,
}: {
  index: number;
  title: string;
  children: React.ReactNode;
  show?: boolean;
  color: Color;
}) {
  const parentId = useContext(ParentCollaspeId);
  return (
    <div className="card">
      <div className="pl-0 card-header collaspe-head">
        <button
          className="btn btn-flat-primary d-flex w-100 justify-content-between collapsed"
          onClick={preventAll}
          data-toggle="collapse"
          data-target={`.${parentId}>.card:nth-child(${index}) .to-collapse`}
        >
          <span className={`d-flex justify-content-between w-100 ${color ? 'text-' + color : ''}`}>
            {title}
            <i className="icon icon-sm">keyboard_arrow_down</i>
          </span>
        </button>
      </div>
      <div className={`collapse to-collapse ${show ? 'show' : ''}`}>
        <div className="card-body pt-0">{children}</div>
      </div>
    </div>
  );
}
