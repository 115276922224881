import { User } from 'model/user';
import { Epic } from 'redux-observable';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { userFetched } from 'store/state/user/user.actions';
import { execRequest } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

const getUser = () => execRequest({ url: `${getConfig().api_url}api/user/` });

export const userInitEpic =
  (requestResult = getUser): Epic<AppAction, AppAction, AppState> =>
  (_action$, _state$) => {
    function requestWithRedirect(): Observable<User> {
      return requestResult().pipe(
        mergeMap((c) => {
          if (c.status === 200) {
            return of(c.response as User);
          }else{
            console.log('Failed to retrieve user: ', c);
          }
          return throwError({});
        }),
      );
    }
    return requestWithRedirect().pipe(
      catchError((e) => {
        console.log('An exception occured while trying to retrieve user: ', e);
        return EMPTY;
      }),
      map(userFetched),
    );
  };
