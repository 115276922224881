import { Client } from 'model/client';
import { ColumnName, EntityType, TableName } from 'model/entity';
import React, { useCallback } from 'react';
import { Collapse, CollaspeList } from '../util/collaspe-list';
import { AlgoFeeStreamTable } from './algo-fee-stream-table';
import { BlockExecution } from './block-execution';
import { RfsDistributionStreamRuleTable } from './dist-table';
import { EspDistribution } from './esp-distribution-stream';

interface ClientComponentProps {
  id: number;
  client: Client;
  fetchDistribution: (name: string) => void;
  fetchAlgo: (name: string) => void;
  editing: boolean;
  ecns: readonly string[];
  currencies: readonly string[];
  products: readonly string[];
  entityType: EntityType;
  deleteLine(tableName: TableName<Client>, line: number): void;
  onChange<T extends TableName<Client>>(
    tableName: T,
    columnName: ColumnName<Client[T][0]>,
    rowId: number,
    value: string,
  ): void;
  addLine(tableName: TableName<Client>, afterIndex: number): void;
}

export function ClientComponent(props: ClientComponentProps) {
  return (
    <CollaspeList id={`id-${props.id}`}>
      <Collapse show index={1} title="Rfs Distribution Stream" color="info">
        <RfsDistributionStreamRuleTable
          addLine={useCallback((i: number) => props.addLine('rfsDistributionStreamRule', i), [props.addLine])}
          values={props.client.rfsDistributionStreamRule}
          deleteLine={useCallback((line) => props.deleteLine('rfsDistributionStreamRule', line), [props.deleteLine])}
          ecns={props.ecns}
          editing={props.editing}
          fetchDistribution={props.fetchDistribution}
          onChange={useCallback(
            (columnName, rowId, value) => props.onChange('rfsDistributionStreamRule', columnName, rowId, value),
            [props.onChange],
          )}
        />
      </Collapse>
      <Collapse show index={2} title="Algo Fees Stream" color="success">
        <AlgoFeeStreamTable
          addLine={useCallback((i: number) => props.addLine('algoFeesRule', i), [props.addLine])}
          values={props.client.algoFeesRule}
          deleteLine={useCallback((line) => props.deleteLine('algoFeesRule', line), [props.deleteLine])}
          ecns={props.ecns}
          editing={props.editing}
          fetchAlgo={props.fetchAlgo}
          onChange={useCallback(
            (columnName, rowId, value) => props.onChange('algoFeesRule', columnName, rowId, value),
            [props.onChange],
          )}
        />
      </Collapse>
      {props.entityType !== 'mass-upload' && (
        <Collapse show index={3} title="Block Execution" color="danger">
          <BlockExecution
            addLine={useCallback((i: number) => props.addLine('executionPreventionRule', i), [props.addLine])}
            values={props.client.executionPreventionRule}
            deleteLine={useCallback((line) => props.deleteLine('executionPreventionRule', line), [props.deleteLine])}
            ecns={props.ecns}
            editing={props.editing}
            currencies={props.currencies}
            onChange={useCallback(
              (columnName, rowId, value) => props.onChange('executionPreventionRule', columnName, rowId, value),
              [props.onChange],
            )}
          />
        </Collapse>
      )}
      {props.entityType !== 'mass-upload' && (
        <Collapse show index={4} title="Esp Distribution Stream" color="warning">
          <EspDistribution
            addLine={useCallback((i: number) => props.addLine('espDistributionStreamRule', i), [props.addLine])}
            values={props.client.espDistributionStreamRule}
            deleteLine={useCallback((line) => props.deleteLine('espDistributionStreamRule', line), [props.deleteLine])}
            ecns={props.ecns}
            editing={props.editing}
            products={props.products}
            onChange={useCallback(
              (columnName, rowId, value) => props.onChange('espDistributionStreamRule', columnName, rowId, value),
              [props.onChange],
            )}
          />
        </Collapse>
      )}
    </CollaspeList>
  );
}
