import { Card } from 'app/utils/card';
import { usePreventCallback } from 'app/utils/event-handler-helpers';
import { ButtonIcon } from 'app/utils/icons';
import { clientValidation, distValidation, translateMessage } from 'app/utils/validators';
import { Entity } from 'model/entity';
import React, { FormEvent, ReactNode } from 'react';
import { EntityTitle } from './entity-title';
import { SaveConfirmation } from './save-confirmation';

interface EntityPanelProps {
  value: Entity;
  onClose: () => void;
  onEdit: () => void;
  onDuplicate: (id: number) => void;
  onSave: () => void;
  onAskConfirm: () => void;
  onCancelConfirm: () => void;
  onValidating: () => void;
  onTitleChange: (title: string) => void;
  alert: (message: string) => void;
  editting: boolean;
  children: ReactNode;
  isAdmin: boolean;
}

export function EntityPanel(props: EntityPanelProps) {
  const doOnEdit = usePreventCallback(() => props.onEdit(), [props.onEdit]);
  const doOnDuplicate = usePreventCallback(
    () => props.onDuplicate(props.value.id),
    [props.onDuplicate, props.value, props.value.id],
  );
  const doOnSubmit = usePreventCallback(
    (e: FormEvent<HTMLFormElement>) => {
      if (props.value.type === 'distribution-stream') {
        const error = distValidation(props.value.value);
        if (error !== undefined) {
          props.alert(translateMessage(error));
          return;
        }
      }
      if (props.value.type === 'client') {
        const errors = clientValidation(props.value.value);
        if (errors.length !== 0) {
          for (const error of errors) {
            props.alert(translateMessage(error));
          }
          return;
        }
      }

      if (e.currentTarget.checkValidity()) {
        props.onAskConfirm();
      } else {
        props.onValidating();
      }
    },
    [props.value.value, props.alert],
  );
  return (
    <form onSubmit={doOnSubmit} className={props.value.state === 'validating' ? 'was-validated' : ''} noValidate>
      <Card
        title={
          <>
            <div className="col-9">
              <EntityTitle value={props.value} onChange={props.onTitleChange} />
            </div>
            <div className="col-3">
              <span className="title-buttons">
                {props.isAdmin && (
                  <>
                    {props.value.state === 'confirming' && (
                      <SaveConfirmation onConfirm={props.onSave} value={props.value} onCancel={props.onCancelConfirm} />
                    )}
                    {props.editting ? (
                      <ButtonIcon type="save" onClick={props.onValidating} />
                    ) : (
                      <>
                        {props.value.type === 'distribution-stream' && (
                          <ButtonIcon type="content_copy" onClick={doOnDuplicate} />
                        )}
                        <ButtonIcon type="edit" onClick={doOnEdit} />
                      </>
                    )}
                  </>
                )}
                <ButtonIcon type="close" onClick={props.onClose} />
              </span>
            </div>
          </>
        }
      >
        {props.children}
      </Card>
    </form>
  );
}
