import { Editable, LimitedEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import { isRequiredValidator, useCurrencyValidator, useIsExactlyValidator, Validator } from 'app/utils/validators';
import { ExecutionPreventionRule } from 'model/client';
import React, { useCallback } from 'react';
import { CommonRowProps } from '../util/table';
import { ClientTableProps } from './types';

interface BlockExecutionProps extends ClientTableProps<'executionPreventionRule'> {
  ecns: readonly string[];
  currencies: readonly string[];
}

export function BlockExecution(props: BlockExecutionProps) {
  const table = createTable(props.values, ['ecn', 'currency', 'result', 'id']);
  const validateCurrency = useCurrencyValidator(props.currencies);
  return (
    <table className="table table-bordered editable-table">
      <thead>
        <tr>
          <th>ECN</th>
          <th>Currency</th>
          <th className="result-column">Result</th>
          {props.editing && (
            <th>
              <Icon type="add" onClick={() => props.addLine()} color="info" />{' '}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <EditableTable<keyof ExecutionPreventionRule, ExecutionPreventionRule>
          values={table}
          addLine={props.addLine}
          resultKey="result"
          rowId="id"
          useOtherConditionGet={false}
          deleteLine={props.deleteLine}
          editing={props.editing}
          size={2}
        >
          {(value, index, onlyResult) => (
            <BlockExecutionRow
              value={value}
              index={index}
              onlyResult={onlyResult}
              editing={props.editing}
              onChange={props.onChange}
              validateCurrency={validateCurrency}
              ecns={props.ecns}
            />
          )}
        </EditableTable>
      </tbody>
    </table>
  );
}

interface BlockExecutionRowProps extends CommonRowProps<ExecutionPreventionRule> {
  validateCurrency: Validator;
  ecns: readonly string[];
}

const BlockExecutionRow = (props: BlockExecutionRowProps) => {
  const ecnChange = useCallback(
    (value: string) => props.onChange('ecn', props.index, value),
    [props.onChange, props.index],
  );
  const currencyChange = useCallback(
    (value: string) => props.onChange('currency', props.index, value),
    [props.onChange, props.index],
  );
  const resultChange = useCallback(
    (value: string) => props.onChange('result', props.index, value),
    [props.onChange, props.index],
  );
  const blockedValidator = useIsExactlyValidator('BLOCKED');
  return (
    <>
      <Cell editing={props.editing} length={props.value.ecn.length} displayed={props.value.ecn.displayed}>
        <LimitedEditable
          defaultValue="*"
          onChange={ecnChange}
          possibleValues={props.ecns}
          editing={props.editing}
          value={props.value.ecn.value}
          validate={isRequiredValidator}
        />
      </Cell>
      <Cell editing={props.editing} length={props.value.currency.length} displayed={props.value.currency.displayed}>
        <Editable
          onChange={currencyChange}
          editing={props.editing}
          value={props.value.currency.value}
          validate={props.validateCurrency}
        />
      </Cell>
      <Cell editing={props.editing} length={props.value.result.length} displayed={props.value.result.displayed} result>
        <Editable
          onChange={resultChange}
          editing={props.editing}
          value={props.value.result.value}
          validate={blockedValidator}
        />
      </Cell>
    </>
  );
};
