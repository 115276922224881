import { FilteredKeysOf } from 'utils/types';
import { AlgoStream, createAlgoLine, newAlgoStream } from './algo-stream';
import { Client, createClientLine } from './client';
import { createDistLine, DistributionStream, modelDistributionStream } from './distribution-stream';

export type Entity = ClientEntity | AlgoStreamEntity | DistributionStreamEntity | MassUploadEntity;

interface EntityBase<T extends string, K> {
  type: T;
  id: number;
  state: 'editing' | 'validating' | 'reading' | 'confirming';
  value: K;
  backup?: K;
}

export type ClientEntity = EntityBase<'client', Client>;
export type AlgoStreamEntity = EntityBase<'algo-stream', AlgoStream>;
export type DistributionStreamEntity = EntityBase<'distribution-stream', DistributionStream>;
export type MassUploadEntity = EntityBase<'mass-upload', Client>;

export type EntityType = Entity['type'];
export type EntityTableName = TableName<Client> | TableName<AlgoStream> | TableName<DistributionStream>;

export type TableName<T> = FilteredKeysOf<T, readonly unknown[]> & string;
export type ColumnType<T, K extends TableName<T>> = T[K] extends readonly unknown[] ? T[K][number] : never;
export type ColumnName<T> = FilteredKeysOf<T, string> & string;

export const newDistributionStreamEntity = (id: number): DistributionStreamEntity => ({
  id,
  type: 'distribution-stream',
  state: 'editing',
  value: modelDistributionStream(),
});

export const newAlgoStreamEntity = (id: number): AlgoStreamEntity => ({
  id,
  type: 'algo-stream',
  state: 'editing',
  value: newAlgoStream(),
});

export const fromAlgoStream = (id: number, algo: AlgoStream): AlgoStreamEntity => ({
  id,
  value: algo,
  type: 'algo-stream',
  state: 'reading',
});

export const fromDistStream = (
  id: number,
  distribution: DistributionStream,
  state: DistributionStreamEntity['state'] = 'reading',
): DistributionStreamEntity => ({
  id,
  value: distribution,
  type: 'distribution-stream',
  state,
});

export const fromClientStream = (id: number, client: Client): ClientEntity => ({
  id,
  value: client,
  type: 'client',
  state: 'reading',
});

export const fromMassUploadStream = (id: number, client: Client): MassUploadEntity => ({
  id,
  value: client,
  type: 'mass-upload',
  state: 'reading',
});

export const ofNotCreated = (entity: Entity) => entity.value.id === undefined;

export const toConfirm = (entity: Entity): Entity => ({
  ...entity,
  state: 'confirming',
});

export const cancelConfirm = (entity: Entity): Entity => ({
  ...entity,
  state: 'editing',
});

export const startValidating = (entity: Entity): Entity => ({
  ...entity,
  state: 'validating',
});
export const cancelEdit = (entity: Entity): Entity =>
  ({
    ...entity,
    state: 'reading',
    value: entity.backup,
    backup: undefined,
  } as Entity);

export const updateName = (title: string, entity: Entity): Entity =>
  ({
    ...entity,
    value: {
      ...entity.value,
      name: title,
    },
  } as Entity);

export const startEditing = (entity: Entity): Entity =>
  ({ ...entity, state: 'editing', backup: entity.value } as Entity);

export const registerSaved = (valueId: number | undefined, entity: Entity): Entity =>
  ({ ...entity, state: 'reading', value: { ...entity.value, id: entity.value.id || valueId } } as Entity);

export const createLine = (type: Entity['type'], columnName: string, id: number) => {
  switch (type) {
    case 'algo-stream':
      return createAlgoLine(id);
    case 'distribution-stream':
      return createDistLine(columnName as TableName<DistributionStream>, id);
    case 'client':
    case 'mass-upload':
      return createClientLine(columnName, id);
    default:
      throw new Error(`column name ${columnName} not found for entity of type ${type}`);
  }
};

export function deleteLine<T extends Entity>(rowIndex: number, tableName: EntityTableName, entity: T): T {
  if (tableName in entity.value) {
    return {
      ...entity,
      value: {
        ...entity.value,
        [tableName]: entity.value[tableName as string].filter((row: any, index: number) => index !== rowIndex),
      },
    };
  }
  console.warn(`tried to delete in ${tableName} for entity ${entity.value.name}. Ignored`);
  return entity;
}
