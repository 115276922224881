export interface ClientOverview {
  id?: number;
  bdrId: string;
  name: string;
}

export interface RfsDistributionStreamRule {
  id: number;
  ecn: string;
  result: string;
}

export interface AlgoFeesDistributionStream {
  id: number;
  ecn: string;
  result: string;
}

export interface ExecutionPreventionRule {
  id: number;
  ecn: string;
  currency: string;
  result: string;
}

export interface EspDistributionStreamRule {
  id: number;
  ecn: string;
  product: string;
  result: string;
}

export interface Client {
  id?: number;
  bdrId: string;
  name: string;
  disabled: boolean;
  comment: null;
  espDistributionStreamRule: readonly EspDistributionStreamRule[];
  executionPreventionRule: readonly ExecutionPreventionRule[];
  rfsDistributionStreamRule: readonly RfsDistributionStreamRule[];
  algoFeesRule: readonly AlgoFeesDistributionStream[];
}

export const newClient = (): Client => ({
  id: undefined,
  bdrId: '',
  name: '',
  disabled: false,
  comment: null,
  executionPreventionRule: [],
  rfsDistributionStreamRule: [],
  algoFeesRule: [],
  espDistributionStreamRule: [],
});

export const createClientLine = (columnName: string, id: number) => {
  switch (columnName) {
    case 'executionPreventionRule':
      return {
        ecn: '',
        currency: '*',
        result: 'BLOCKED',
      };
    case 'rfsDistributionStreamRule':
      return {
        ecn: '',
        result: '',
      };
    case 'algoFeesRule':
      return {
        ecn: '*',
        result: '',
      };
    case 'espDistributionStreamRule':
      return {
        ecn: '',
        product: '',
        result: '',
      };
    default:
      throw Error();
  }
};
