import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';

export interface ClientSearchFormByStreamNameState {
  readonly name: string;
}

const initialState: ClientSearchFormByStreamNameState = {
  name: '',
};

export const clientSearchByStreamNameReducer: Reducer<ClientSearchFormByStreamNameState, AppAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case 'SearchForm.ClientByStreamName.bdrInputChanged':
      return {
        ...state,
        name: action.bdrId,
      };
    case 'SearchForm.ClientByStreamName.formSubmitted':
      return {
        ...state,
        name: state.name.trim(),
      };

    case 'SearchForms.reset':
      return { ...state, name: initialState.name };
    default:
      return state;
  }
};
