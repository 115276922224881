import { ajax, AjaxConfig } from 'rxjs/ajax';
import { fakeUser, sgwtConnect } from './sgwtConnect';

const withAuthorization = (request: AjaxConfig) => ({
  ...request,
  headers: {
    Authorization: sgwtConnect.getAuthorizationHeader(),
    ['X-Fake-Authentication']: fakeUser,
    ...(request.headers || {}),
  },
});

export const getJSON = <T>(path: string, params: object = {}) =>
  ajax.getJSON<T>(path, {
    Authorization: sgwtConnect.getAuthorizationHeader()!,
    ['X-Fake-Authentication']: fakeUser!,
    ...params,
  });

export const execRequest = <T>(urlOrRequest: AjaxConfig) => ajax<T>(withAuthorization(urlOrRequest));
