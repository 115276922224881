import { Referentials } from 'model/referentials';
import { Epic } from 'redux-observable';
import { of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import { referentialFetched } from 'store/state/referentials/referentials.actions';
import { execRequest } from 'utils/http';
import { getConfig, sgwtConnect } from 'utils/sgwtConnect';

const getReferentials = () =>
  execRequest<Referentials>({ url: `${getConfig().api_url}api/referentials/` }).pipe(
    mergeMap((x) => {
      if (x.status === 200) {
        return of(x.response);
      }
      return throwError(x);
    }),
  );

export const referentialInitEpic =
  (requestResult = getReferentials): Epic<AppAction, AppAction, AppState> =>
    (_action$, _state$) =>
      requestResult().pipe(
        map(referentialFetched),
        catchError((error) => of(addAlert(`error while filling referential data : error ${error} and isAuthorized ${sgwtConnect.isAuthorized()}`))),
      );
