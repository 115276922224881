import { Entity, ofNotCreated } from 'model/entity';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import { savedEntity } from 'store/state/entity-list/entity-list.actions';
import { execRequest } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

const getUrl = (entity: Entity) => {
  console.log(getConfig());

  switch (entity.type) {
    case 'distribution-stream':
      return (
        `${getConfig().api_url}api/distributionstream?name=` +
        (ofNotCreated(entity) ? '' : encodeURIComponent(entity.value.name))
      );
    case 'algo-stream':
      return (
        `${getConfig().api_url}api/algostream?name=` +
        (ofNotCreated(entity) ? '' : encodeURIComponent(entity.value.name))
      );
    case 'mass-upload':
      return `${getConfig().api_url}api/client/mass-upload-clients`;
    case 'client':
      return `${getConfig().api_url}api/client/` + (ofNotCreated(entity) ? '' : encodeURIComponent(entity.value.bdrId));
  }
};

const save = (entity: Entity, bdrIds: string) =>
  execRequest<{ id?: number }>({
    url: getUrl(entity),
    body:
      entity.type !== 'mass-upload'
        ? entity.value
        : {
            bdrIds,
            targetSetupClient: entity.value,
          },
    headers: {
      'Content-Type': 'application/json',
    },
    method: entity.value.id === undefined ? 'POST' : 'PUT',
  });

export const saveEntityEpic =
  (saveRequest = save): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('EntityList.saveEntity' as const),
      withLatestFrom(state$),
      map(([a, { entityList }]) => entityList.entities.find((c: Entity) => c.id === a.entityId)),
      filter((entity): entity is Entity => Boolean(entity)),
      switchMap((entity) =>
        saveRequest(entity, state$.value.massiveUploadForm.bdrIds).pipe(
          map((result) => savedEntity(entity.id, result.response?.id)),
          catchError((e) => {
            console.error(e);
            return of(addAlert('error while saving'));
          }),
        ),
      ),
    );
