import { TenorPair } from 'app/utils/editable';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'app/utils/modal';
import { AlgoFeeRule } from 'model/algo-stream';
import {
  AlgoFeesDistributionStream,
  EspDistributionStreamRule,
  ExecutionPreventionRule,
  RfsDistributionStreamRule,
} from 'model/client';
import { MarginCategoryRule, PublishedBucketsRule, StreamAliasRule, TradingGroupRule } from 'model/distribution-stream';
import {
  AlgoStreamEntity,
  ClientEntity,
  DistributionStreamEntity,
  Entity,
  MassUploadEntity,
  TableName,
} from 'model/entity';
import React, { useContext, useEffect, useState } from 'react';
import { never } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getFilteredBdrIdNotInUlysse } from 'store/api/massUploadApi';
import { MassUploadContext } from './entity';

function tryGetTable<T, K extends TableName<T>>(e: T | undefined, k: K): T[K] {
  if (e == null) {
    return [] as unknown as T[K];
  }
  return e[k];
}

const ClientSaveConfirmation = (props: { value: ClientEntity }) => (
  <>
    <TableDiff title="Distribution Streams" data={props.value}>
      {(value) => (
        <ClientDistributionStreamSaveConfirmationTable values={tryGetTable(value, 'rfsDistributionStreamRule')} />
      )}
    </TableDiff>
    <TableDiff title="Algo Streams" data={props.value}>
      {(value) => <ClientAlgoFeeStreamSaveConfirmationTable values={tryGetTable(value, 'algoFeesRule')} />}
    </TableDiff>
    <TableDiff title="Block Execution" data={props.value}>
      {(value) => <ClientBlockExecutionSaveConfirmationTable values={tryGetTable(value, 'executionPreventionRule')} />}
    </TableDiff>
    <TableDiff title="Esp Distribution Stream Rule" data={props.value}>
      {(value) => <ClientEspDistributionStreamRuleTable values={tryGetTable(value, 'espDistributionStreamRule')} />}
    </TableDiff>
  </>
);

const MassUploadSaveConfirmation = (props: {
  value: MassUploadEntity;
  toggleConfirmButtonDisabled: (a: boolean) => void;
}) => {
  const [status, setStatus] = useState<{
    level: 'info' | 'warning' | 'success' | 'danger';
    message: string;
  }>({
    level: 'info',
    message: 'Checking bdrIds not in ulysse...',
  });

  const trimChar = (input: string, charToRemove: string) => {
    while (input.charAt(0) === charToRemove) {
      input = input.substring(1);
    }

    while (input.charAt(input.length - 1) === charToRemove) {
      input = input.substring(0, input.length - 1);
    }

    return input;
  };

  const bdrIds = trimChar(useContext(MassUploadContext), ';');

  const isNumber = (value: string | number): boolean =>
    value != null && value !== '' && !isNaN(Number(value.toString()));

  useEffect(() => {
    if (bdrIds === undefined || bdrIds.trim() === '') {
      setStatus({
        level: 'danger',
        message: 'You need to enter at least one bdrId to use mass upload',
      });
      props.toggleConfirmButtonDisabled(true);
    } else if (bdrIds.length > 0 && bdrIds.split(';').some((a) => isNumber(a.trim()) === false)) {
      setStatus({
        level: 'danger',
        message: 'One of your bdrId is not a number. Cannot use mass upload.',
      });
      props.toggleConfirmButtonDisabled(true);
    } else if (
      props.value.value.rfsDistributionStreamRule.length === 0 &&
      props.value.value.algoFeesRule.length === 0
    ) {
      setStatus({
        level: 'danger',
        message: 'You need to enter at least one rfs distribution stream or one algo fees. Cannot use mass upload.',
      });
    } else {
      getFilteredBdrIdNotInUlysse(bdrIds === undefined ? '' : bdrIds)
        .pipe(
          map((c) => {
            if (c.status === 200) {
              const bdrIds = c.response as string[];

              if (bdrIds.length === 0) {
                setStatus({
                  level: 'success',
                  message: 'All bdrIds exist in Ulysse, please confirm to begin mass upload',
                });
              } else {
                setStatus({
                  level: 'warning',
                  message: `<p>Warning, the following bdrId(s) does not exist in Ulysse:</p><p>${bdrIds.join(
                    ',',
                  )}</p> <p>Please confirm to begin mass upload</p>`,
                });
              }

              props.toggleConfirmButtonDisabled(false);
            } else {
              console.log('Error: ' + JSON.stringify(c));

              setStatus({
                level: 'danger',
                message: 'Failed to save. Please contact your support',
              });
              props.toggleConfirmButtonDisabled(true);
            }
          }),
          catchError((e) => {
            console.log('Error thrown: ', JSON.stringify(e));

            props.toggleConfirmButtonDisabled(true);

            setStatus({
              level: 'danger',
              message: `Failed to check bdrIds in Ulysse, please retry again.`,
            });
            return never();
          }),
        )
        .subscribe();
    }
  }, []);

  function createStatusOutput() {
    return { __html: status.message };
  }

  return (
    <>
      <h2>Distribution Streams</h2>
      <div className="row">
        <ClientDistributionStreamSaveConfirmationTable values={props.value.value.rfsDistributionStreamRule} />
      </div>
      <h2>Algo Streams</h2>
      <div className="row">
        <ClientAlgoFeeStreamSaveConfirmationTable values={props.value.value.algoFeesRule} />
      </div>
      <div className="row" style={{ marginTop: '40px' }}>
        <div className={`alert alert-${status.level}`} dangerouslySetInnerHTML={createStatusOutput()} />
      </div>
    </>
  );
};

function TableDiff<T extends Entity>(props: {
  title?: string;
  data: T;
  children: (values: T['value'] | undefined) => React.ReactElement;
}) {
  return (
    <>
      {props.title && <h2>{props.title}</h2>}
      <div className="row">
        <div className="col-6">{props.children(props.data.backup)}</div>
        <div className="col-6">{props.children(props.data.value)}</div>
      </div>
    </>
  );
}
const ClientBlockExecutionSaveConfirmationTable = (props: { values: readonly ExecutionPreventionRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>ECN</th>
        <th>Currency</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((c) => (
        <tr key={c.id}>
          <td>{c.ecn}</td>
          <td>{c.currency}</td>
          <td>{c.result}</td>
        </tr>
      ))}
    </tbody>
  </table>
);
const ClientDistributionStreamSaveConfirmationTable = (props: { values: readonly RfsDistributionStreamRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>ECN</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((c, i) => (
        <tr key={i}>
          <td>{c.ecn}</td>
          <td>{c.result}</td>
        </tr>
      ))}
    </tbody>
  </table>
);
const ClientAlgoFeeStreamSaveConfirmationTable = (props: { values: readonly AlgoFeesDistributionStream[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>ECN</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((c, i) => (
        <tr key={i}>
          <td>{c.ecn}</td>
          <td>{c.result}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const ClientEspDistributionStreamRuleTable = (props: { values: readonly EspDistributionStreamRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>ECN</th>
        <th>Product</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((c) => (
        <tr key={c.id}>
          <td>{c.ecn}</td>
          <td>{c.product}</td>
          <td>{c.result}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const AlgoFeesSaveConfirmation = (props: { value: AlgoStreamEntity }) => (
  <TableDiff data={props.value}>
    {(value) => <AlgoFeesSaveConfirmationTable values={tryGetTable(value, 'algoFeesRules')} />}
  </TableDiff>
);
const AlgoFeesSaveConfirmationTable = (props: { values: readonly AlgoFeeRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Algo Type</th>
        <th>Currency Group</th>
        <th>Internal/External</th>
        <th>Client Fees</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((c) => (
        <tr key={c.id}>
          <td>{c.algoType}</td>
          <td>{c.currencyGroup}</td>
          <td>{c.internalExternal}</td>
          <td>{c.clientFees}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const MarginCategoryRuleConfirmationTable = (props: { values: readonly MarginCategoryRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Product</th>
        <th>Currency</th>
        <th>Tenor</th>
        {props.values.some((c) => !!c.bidAsk) === true && <th>Bid/Ask</th>}
        {props.values.some((c) => !!c.bidAsk) === false && <th>Result</th>}
      </tr>
    </thead>
    <tbody>
      {props.values.map((marginCategory) => (
        <tr key={marginCategory.id}>
          <td>{marginCategory.product}</td>
          <td>{marginCategory.currency}</td>
          <td>
            <TenorPair pair={marginCategory.tenor} />
          </td>
          {props.values.some((c) => !!c.bidAsk) === true && <td>{marginCategory.bidAsk || 'REJECTED'}</td>}
          {props.values.some((c) => !!c.bidAsk) === false && <td>{marginCategory.result || 'REJECTED'}</td>}
        </tr>
      ))}
    </tbody>
  </table>
);

const TradingGroupRuleConfirmationTable = (props: { values: readonly TradingGroupRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Product</th>
        <th>Currency</th>
        <th>Tenor</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((tradingGroup) => (
        <tr key={tradingGroup.id}>
          <td>{tradingGroup.product}</td>
          <td>{tradingGroup.currency}</td>
          <td>
            <TenorPair pair={tradingGroup.tenor} />
          </td>
          <td>{tradingGroup.result || 'REJECTED'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const StreamAliasRuleConfirmationTable = (props: { values: readonly StreamAliasRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Ecn</th>
        <th>Ecn Alias</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((streamAlias) => (
        <tr key={streamAlias.id}>
          <td>{streamAlias.ecn}</td>
          <td>{streamAlias.result}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const PublishedBucketsRuleConfirmationTable = (props: { values: readonly PublishedBucketsRule[] }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Ecn</th>
        <th>Stream Alias</th>
        <th>Currency</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {props.values.map((publishedBucket) => (
        <tr key={publishedBucket.id}>
          <td>{publishedBucket.ecn}</td>
          <td>{publishedBucket.streamAlias}</td>
          <td>{publishedBucket.currency}</td>
          <td>{publishedBucket.result || 'REJECTED'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

const DistributionStreamSaveConfirmation = (props: { value: DistributionStreamEntity }) => (
  <>
    {props.value.value.numberLinked > 1 && (
      <div className="alert alert-warning">
        Warning : This stream is linked to {props.value.value.numberLinked} clients !
      </div>
    )}

    <TableDiff title="Trading Group" data={props.value}>
      {(value) => <TradingGroupRuleConfirmationTable values={tryGetTable(value, 'tradingGroupRule')} />}
    </TableDiff>
    <TableDiff title="Margin Category" data={props.value}>
      {(value) => <MarginCategoryRuleConfirmationTable values={tryGetTable(value, 'marginCategoryRule')} />}
    </TableDiff>
    <TableDiff title="Stream Alias" data={props.value}>
      {(value) => <StreamAliasRuleConfirmationTable values={tryGetTable(value, 'streamAliasRule')} />}
    </TableDiff>
    <TableDiff title="Published Bucket" data={props.value}>
      {(value) => <PublishedBucketsRuleConfirmationTable values={tryGetTable(value, 'publishedBucketsRule')} />}
    </TableDiff>
  </>
);

export function SaveConfirmation(props: { onConfirm: () => void; value: Entity; onCancel: () => void }) {
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(props.value.type === 'mass-upload');
  const [isClicked, setIsClicked] = useState(false);

  const onConfirmClick = () => {
    setIsClicked(true);
    props.onConfirm();
  };

  return (
    <Modal>
      <ModalHeader onClose={props.onCancel}>Save Confirmation</ModalHeader>
      <ModalBody>
        {props.value.type === 'client' && <ClientSaveConfirmation value={props.value} />}
        {props.value.type === 'mass-upload' && (
          <MassUploadSaveConfirmation
            value={props.value}
            toggleConfirmButtonDisabled={(a: boolean) => setIsConfirmDisabled(a)}
          />
        )}
        {props.value.type === 'algo-stream' && <AlgoFeesSaveConfirmation value={props.value} />}
        {props.value.type === 'distribution-stream' && <DistributionStreamSaveConfirmation value={props.value} />}
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-lg btn-link" onClick={props.onCancel} data-dismiss="modal">
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-lg btn-primary"
          data-dismiss="modal"
          onClick={onConfirmClick}
          disabled={isConfirmDisabled || isClicked}
        >
          {isClicked && (
            <span
              className="spinner-border spinner-grow-sm"
              style={{ width: '1.2rem', height: '1.2rem' }}
              role="status"
              aria-hidden="true"
            />
          )}{' '}
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
}
