import { EditableButton, LimitedEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import { isRequiredValidator, useAllValidator, useMustBeAlgoNameValidator, Validator } from 'app/utils/validators';
import { AlgoFeesDistributionStream } from 'model/client';
import { useCallback } from 'react';
import React from 'react';
import { CommonRowProps } from '../util/table';
import { ClientTableProps } from './types';

interface AlgoFeeStreamTableProps extends ClientTableProps<'algoFeesRule'> {
  ecns: readonly string[];
  fetchAlgo(name: string): void;
}

export function AlgoFeeStreamTable(props: AlgoFeeStreamTableProps) {
  const algoValidator = useAllValidator(isRequiredValidator, useMustBeAlgoNameValidator());

  const table = createTable(props.values, ['ecn', 'result', 'id']);
  return (
    <table className="table table-bordered editable-table">
      <thead>
        <tr>
          <th>ECN</th>
          <th className="result-column">Result</th>
          {props.editing && (
            <th>
              <Icon type="add" onClick={() => props.addLine()} color="info" />{' '}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <EditableTable<keyof AlgoFeesDistributionStream, AlgoFeesDistributionStream>
          values={table}
          editing={props.editing}
          useOtherConditionGet={false}
          rowId="id"
          resultKey="result"
          size={2}
          addLine={props.addLine}
          deleteLine={props.deleteLine}
        >
          {(value, index, onlyResult) => (
            <AlgoFeeStreamRow
              value={value}
              index={index}
              onlyResult={onlyResult}
              algoValidator={algoValidator}
              ecns={props.ecns}
              editing={props.editing}
              fetchAlgo={props.fetchAlgo}
              onChange={props.onChange}
            />
          )}
        </EditableTable>
      </tbody>
    </table>
  );
}

interface AlgoFeeStreamRowProps extends CommonRowProps<AlgoFeesDistributionStream> {
  ecns: readonly string[];
  algoValidator: Validator;
  fetchAlgo(name: string): void;
}
const AlgoFeeStreamRow = (props: AlgoFeeStreamRowProps) => {
  const doOnClick = useCallback(() => props.fetchAlgo(props.value.result.value), [props.fetchAlgo]);
  const doOnEcnChange = useCallback(
    (value: string) => props.onChange('ecn', props.index, value),
    [props.onChange, props.index],
  );
  const doOnResultChange = useCallback(
    (value: string) => props.onChange('result', props.index, value),
    [props.onChange, props.index],
  );

  return (
    <>
      <Cell displayed={props.value.ecn.displayed} length={props.value.ecn.length} editing={props.editing}>
        <LimitedEditable
          onChange={doOnEcnChange}
          defaultValue="*"
          possibleValues={props.ecns}
          editing={props.editing}
          value={props.value.ecn.value}
        />
      </Cell>
      <Cell displayed={props.value.result.displayed} length={props.value.result.length} result editing={props.editing}>
        <EditableButton
          onChange={doOnResultChange}
          editing={props.editing}
          value={props.value.result.value}
          onClick={doOnClick}
          validate={props.algoValidator}
        />
      </Cell>
    </>
  );
};
