import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';

export interface MassiveUploadState {
  readonly bdrIds: string;
}

const initialState: MassiveUploadState = {
  bdrIds: '',
};
export const massiveUploadReducer: Reducer<MassiveUploadState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'MassUpload.updateBdrIds':
      return { bdrIds: action.values };
    default:
      return state;
  }
};
