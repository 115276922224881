import { Client } from 'model/client';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import { clientSearchSuccess } from 'store/state/search-result/search-result.actions';
import { getJSON } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

const searchByBdrId = (name: string) => getJSON<Client[]>(`${getConfig().api_url}api/client/?streamName=` + name);

export const clientSearchByStreamNameEpic =
  (requestResults = searchByBdrId): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('SearchForm.ClientByStreamName.formSubmitted' as const),
      withLatestFrom(state$),
      switchMap(([, { clientSearchByStreamNameForm }]) =>
        requestResults(clientSearchByStreamNameForm.name.trim()).pipe(
          map(clientSearchSuccess),
          catchError((e) => {
            console.error(e);
            return of(addAlert('error while searching client'));
          }),
        ),
      ),
    );
