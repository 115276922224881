import { usePreventCallback, useTargetValueCallback } from 'app/utils/event-handler-helpers';
import React from 'react';

interface SearchFormProps {
  value: string;
  valueChanged: (bdrId: string) => void;
  formSubmitted: () => void;
  title: string;
}
export function SearchForm(props: SearchFormProps) {
  return (
    <form onSubmit={usePreventCallback(props.formSubmitted)}>
      <div className="form-group">
        <label>
          {props.title}
          <div className="input-group">
            <input className="form-control" value={props.value} onChange={useTargetValueCallback(props.valueChanged)} />
            <div className="input-group-append">
              <input type="submit" value="search" className="btn btn-primary" />
            </div>
          </div>
        </label>
      </div>
    </form>
  );
}
