import { AlgoStreamSearchRow } from 'model/algo-stream';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import { algoStreamSearchSuccess } from 'store/state/search-result/search-result.actions';
import { getJSON } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

const searchByName = (name: string) =>
  getJSON<AlgoStreamSearchRow[]>(`${getConfig().api_url}api/algostream/search?name=` + encodeURIComponent(name));

export const algoStreamSearchEpic =
  (requestResults = searchByName): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('SearchForm.AlgoStream.formSubmitted' as const),
      withLatestFrom(state$),
      switchMap(([, { algoStreamSearchForm }]) =>
        requestResults(algoStreamSearchForm.name).pipe(
          map(algoStreamSearchSuccess),
          catchError((e) => {
            console.error(e);
            return of(addAlert('error while searching algo stream'));
          }),
        ),
      ),
    );
