import { User } from 'model/user';
import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';

export interface UserState {
  user: User;
}

const initialState: UserState = {
  user: { isAdmin: false },
};

export const userReducer: Reducer<UserState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'User.fetched':
      return { user: action.user };
    default:
      return state;
  }
};
