import { AlgoStream } from 'model/algo-stream';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import {
  displayAlgoStream,
  fetchAlgoStreamDetails,
  highlightEntity,
} from 'store/state/entity-list/entity-list.actions';
import { EntityListState } from 'store/state/entity-list/entity-list.reducer';
import { execRequest } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

export const getAlgoByName = (name: string) =>
  execRequest<AlgoStream>({ url: `${getConfig().api_url}api/algostream?name=` + encodeURIComponent(name) });

export const addAlgoStream = (
  requestResults: (s: string) => Observable<AjaxResponse<AlgoStream>>,
  action: ReturnType<typeof fetchAlgoStreamDetails>,
  entityList: EntityListState,
): Observable<AppAction> => {
  const entity = entityList.entities.find((c) => c.type === 'algo-stream' && c.value.name === action.name);

  return entity
    ? of(highlightEntity(entity))
    : requestResults(action.name).pipe(
        map((c) => {
          if (c.status === 200) {
            return displayAlgoStream(c.response);
          }

          return addAlert(`algo stream "${action.name}" : not found`);
        }),
        catchError((e) => {
          console.error(e);
          return of(addAlert(`algo stream "${action.name}" : server error`));
        }),
      );
};

export const algoStreamDetailsEpic =
  (requestResults = getAlgoByName): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('EntityList.fetchAlgoStreamDetails' as const),
      withLatestFrom(state$),
      switchMap(([s, { entityList }]) => addAlgoStream(requestResults, s, entityList)),
    );
