import { Editable, LimitedEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import {
  isNumberValidator,
  isRequiredValidator,
  useAllValidator,
  useAnyValidator,
  useIsExactlyValidator,
  Validator,
} from 'app/utils/validators';
import { AlgoFeeRule, AlgoStream } from 'model/algo-stream';
import { ColumnName, TableName } from 'model/entity';
import React, { useCallback } from 'react';
import { CommonRowProps } from '../util/table';

interface AlgoStreamComponentProps {
  value: AlgoStream;
  editing: boolean;
  algoTypes: readonly string[];
  internalExternals: readonly string[];
  currencyGroups: readonly string[];

  onChange<T extends TableName<AlgoStream>>(
    tableName: T,
    columnName: ColumnName<AlgoStream[T][0]>,
    rowId: number,
    value: string,
  ): void;
  deleteLine<T extends TableName<AlgoStream>>(tableName: T, rowId: number): void;
  addLine(tableName: TableName<AlgoStream>, afterIndex?: number): void;
}

export function AlgoStreamComponent(props: AlgoStreamComponentProps) {
  const onChange = useCallback(
    (column: ColumnName<AlgoFeeRule>, index: number, value: string) =>
      props.onChange('algoFeesRules', column, index, value),
    [props.onChange],
  );

  const table = createTable(props.value.algoFeesRules, [
    'algoType',
    'currencyGroup',
    'internalExternal',
    'clientFees',
    'id',
  ]);
  const doAddLine = useCallback(
    (index: number) => {
      props.addLine('algoFeesRules', index);
    },
    [props.addLine],
  );

  const doDeleteLine = useCallback(
    (index: number) => {
      props.deleteLine('algoFeesRules', index);
    },
    [props.deleteLine],
  );
  const feeValidator = useAllValidator(
    isRequiredValidator,
    useAnyValidator(isNumberValidator, useIsExactlyValidator('REJECTED')),
  );

  return (
    <div>
      <table className="table table-bordered editable-table">
        <thead>
          <tr>
            <th>Algo Type</th>
            <th>Currency Group</th>
            <th>Internal/External</th>
            <th className="result-column">Client Fees</th>
            {props.editing && (
              <th>
                <Icon type="add" onClick={() => props.addLine('algoFeesRules')} color="info" />{' '}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <EditableTable<keyof AlgoFeeRule, AlgoFeeRule>
            values={table}
            editing={props.editing}
            useOtherConditionGet
            rowId="id"
            resultKey="clientFees"
            size={4}
            addLine={doAddLine}
            deleteLine={doDeleteLine}
          >
            {(c, currentIndex, onlyResult) => (
              <AlgoStreamRow
                editing={props.editing}
                onlyResult={onlyResult}
                index={currentIndex}
                value={c}
                algoTypes={props.algoTypes}
                internalExternals={props.internalExternals}
                currencyGroups={props.currencyGroups}
                feeValidator={feeValidator}
                onChange={onChange}
              />
            )}
          </EditableTable>
        </tbody>
      </table>
    </div>
  );
}

interface AlgoStreamProps extends CommonRowProps<AlgoFeeRule> {
  feeValidator: Validator;
  algoTypes: readonly string[];
  internalExternals: readonly string[];
  currencyGroups: readonly string[];
}

const AlgoStreamRow = (props: AlgoStreamProps) => {
  const algoTypeUpdate = useCallback(
    (value: string) => props.onChange('algoType', props.index, value),
    [props.onChange, props.index],
  );
  const currencyGroupUpdate = useCallback(
    (value: string) => props.onChange('currencyGroup', props.index, value),
    [props.onChange, props.index],
  );
  const internalExternalUpdate = useCallback(
    (value: string) => props.onChange('internalExternal', props.index, value),
    [props.onChange, props.index],
  );

  const resultUpdate = useCallback(
    (value: string) => {
      props.onChange('clientFees', props.index, value);
    },
    [props.onChange, props.index],
  );
  return (
    <>
      <Cell editing={props.editing} length={props.value.algoType.length} displayed={props.value.algoType.displayed}>
        <LimitedEditable
          defaultValue="*"
          disabled={props.onlyResult}
          onChange={algoTypeUpdate}
          possibleValues={props.algoTypes}
          editing={props.editing}
          value={props.value.algoType.value}
        />
      </Cell>
      <Cell
        editing={props.editing}
        length={props.value.currencyGroup.length}
        displayed={props.value.currencyGroup.displayed}
      >
        <LimitedEditable
          defaultValue="*"
          disabled={props.onlyResult}
          onChange={currencyGroupUpdate}
          possibleValues={props.currencyGroups}
          editing={props.editing}
          value={props.value.currencyGroup.value}
        />
      </Cell>
      <Cell
        editing={props.editing}
        length={props.value.internalExternal.length}
        displayed={props.value.internalExternal.displayed}
      >
        <LimitedEditable
          defaultValue="*"
          disabled={props.onlyResult}
          onChange={internalExternalUpdate}
          possibleValues={props.internalExternals}
          editing={props.editing}
          value={props.value.internalExternal.value}
        />
      </Cell>
      <Cell
        editing={props.editing}
        length={props.value.clientFees.length}
        displayed={props.value.clientFees.displayed}
        result
      >
        <Editable
          onChange={resultUpdate}
          editing={props.editing}
          value={props.value.clientFees.value}
          validate={props.feeValidator}
        />
      </Cell>
    </>
  );
};
