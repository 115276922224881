import React, { ReactNode } from 'react';

interface CardProps {
  id?: string;
  title: ReactNode;
  children: ReactNode;
  className?: string;
}

export function Card(props: CardProps) {
  return (
    <div id={props.id} className={'card card-bordered ' + (props.className || '')}>
      <div className="card-body">
        <div className="card-title row">{props.title}</div>
        <div className="card-text">{props.children}</div>
      </div>
    </div>
  );
}
