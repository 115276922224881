import { AlgoStreamSearchRow } from 'model/algo-stream';
import { Client, ClientOverview } from 'model/client';
import { DistributionStreamSearchRow } from 'model/distribution-stream';
import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';

export type SearchResultState =
  | { readonly type: 'init' }
  | { readonly type: 'error'; readonly message: string }
  | { readonly type: 'single-client-success'; readonly client: Client }
  | { readonly type: 'client-success'; readonly values: readonly ClientOverview[] }
  | { readonly type: 'algo-stream-success'; readonly values: readonly AlgoStreamSearchRow[] }
  | {
      readonly type: 'distribution-stream-success';
      readonly values: readonly DistributionStreamSearchRow[];
      readonly searched: string;
    }
  | { readonly type: 'display-bdrids-mass-upload' }
  | { readonly type: 'no-result' };

const initialState: SearchResultState = {
  type: 'init',
};

export const searchResultReducer: Reducer<SearchResultState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SearchResult.singleClientSearchSuccess':
      return { type: 'single-client-success', client: action.client };
    case 'SearchResult.clientSearchSuccess':
      return { type: 'client-success', values: action.clients };
    case 'SearchResult.clientSearchError':
      return { type: 'error', message: action.message };
    case 'SearchResult.distributionStreamSearchSuccess':
      return { type: 'distribution-stream-success', values: action.distributionStreams, searched: action.searched };
    case 'SearchResult.algoStreamSearchSuccess':
      return { type: 'algo-stream-success', values: action.algoStreams };
    case 'SearchResult.displayBdrIdsMassUpload':
      return { type: 'display-bdrids-mass-upload' };
    default:
      return state;
  }
};
