import { Client } from 'model/client';
import { isValidCurrency } from 'model/currency';
import { DistributionStream } from 'model/distribution-stream';
import { ChangeEventHandler, FormEvent, MutableRefObject, useCallback } from 'react';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, map, startWith, switchMap, tap } from 'rxjs/operators';
import { getAlgoByName } from 'store/epic/algo-stream-details.epic';
import { getDistByName } from 'store/epic/distribution-stream-details.epic';
import { useEventCallback } from './rxjs-hooks';

export type ValidationError = string;
export type ValidatorResult = ValidationError | undefined;
export type Validator = (input: string) => Observable<ValidatorResult>;

export const useCurrencyValidator = (currencies: readonly string[]): Validator =>
  useAllValidator(
    isRequiredValidator,
    useCallback(
      (input: string) => of(isValidCurrency(input, currencies) ? undefined : 'invalid currency'),
      [currencies],
    ),
  );

export const useMustBeDistNameValidator = (): Validator =>
  useCallback((input: string) => {
    if (input === '') {
      return of(undefined);
    }
    const checkStatus = (result: AjaxResponse<unknown>) => {
      if (result.status === 404) {
        return 'The distribution stream name does not exist';
      }

      if (result.status >= 200 && result.status < 300) {
        return undefined;
      }

      return 'error during existence check';
    };
    return getDistByName(input).pipe(
      map(checkStatus),
      catchError((c) => of(checkStatus(c))),
      startWith('Still checking if name exists'),
    );
  }, []);

export const useMustBeAlgoNameValidator = (): Validator =>
  useCallback((input: string) => {
    if (input === '') {
      return of(undefined);
    }
    const checkStatus = (result: AjaxResponse<unknown>) => {
      if (result.status === 404) {
        return 'The algo stream name does not exist';
      }

      if (result.status >= 200 && result.status < 300) {
        return undefined;
      }

      return 'error during existence check';
    };

    return getAlgoByName(input).pipe(
      map(checkStatus),
      catchError((c) => of(checkStatus(c))),
      startWith('Still checking if name exists'),
    );
  }, []);

export const useMustNotBeDistNameValidator = (): Validator =>
  useCallback((input: string) => {
    if (input === '') {
      return of(undefined);
    }
    const checkStatus = (result: AjaxResponse<unknown>) => {
      if (result.status >= 200 && result.status < 300) {
        return 'The distribution stream name already exists';
      }
      if (result.status === 404) {
        return undefined;
      }
      return 'error during existence check';
    };
    return getDistByName(input).pipe(
      map(checkStatus),
      catchError((c) => of(checkStatus(c))),
      startWith('Still checking if name exists'),
    );
  }, []);

export const useMustNotBeAlgoNameValidator = (): Validator =>
  useCallback((input: string) => {
    if (input === '') {
      return of(undefined);
    }
    const checkStatus = (result: AjaxResponse<unknown>) => {
      if (result.status >= 200 && result.status < 300) {
        return 'The algo stream name already exists';
      }
      if (result.status === 404) {
        return undefined;
      }
      return 'error during existence check';
    };
    return getAlgoByName(input).pipe(
      map(checkStatus),
      catchError((c) => of(checkStatus(c))),
      startWith('Still checking if name exists'),
    );
  }, []);

export const useNotEmptyValidator: Validator = (input) => of(input.length === 0 ? undefined : 'should be empty');

export const useAllValidator = (...validators: readonly Validator[]): Validator =>
  useCallback(
    (input: string) =>
      combineLatest(...validators.map((v) => v(input))).pipe(
        map((xs) => (xs.every((c) => c === undefined) ? undefined : xs.filter((c) => c !== undefined).join(' AND '))),
      ),
    [...validators],
  );

export const useAnyValidator = (...validators: readonly Validator[]): Validator =>
  useCallback(
    (input: string) =>
      combineLatest(...validators.map((v) => v(input))).pipe(
        map((xs) => (xs.some((c) => c === undefined) ? undefined : xs.join(' OR '))),
      ),
    [...validators],
  );

export const useIsInList = (values: readonly string[]): Validator =>
  useCallback(
    (input: string) =>
      of(values.some((c) => c === input) ? undefined : `should be one of ${values.map((c) => `'${c}'`).join(', ')}`),
    [...values],
  );

export const useInvalidCharactersValidator = (cs: readonly string[]): Validator =>
  useCallback(
    (input: string) => {
      const result = cs.some((c) => input.includes(c));

      return of(result ? `should not contains any of ${cs.map((c) => `'${c}'`).join(', ')}` : undefined);
    },
    [...cs],
  );

export const createValidatedOnChange = <T extends HTMLInputElement | HTMLSelectElement>(
  target: MutableRefObject<T | null>,
  onChange: (input: string) => void,
  validate: Validator | undefined,
  defaultValue: string | undefined,
): [ChangeEventHandler<T>, ValidatorResult] =>
  useEventCallback(
    (event$: Observable<FormEvent<T>>) =>
      event$.pipe(
        startWith(undefined), // in order to be validated at the begining
        switchMap((inputEvent) => {
          if (target.current === null) {
            return EMPTY;
          }
          if (inputEvent !== undefined) {
            // only during startwith, useful during validation
            onChange(target.current.value === defaultValue ? '' : target.current.value);
          }
          validate = validate || isAlwaysTrueValidator;
          return validate(target.current.value).pipe(tap((result) => target.current?.setCustomValidity(result || '')));
        }),
        catchError(() => EMPTY),
      ),
    [target.current, onChange, validate],
  );
export const isAlwaysTrueValidator: Validator = () => of(undefined);

export const isNumberValidator: Validator = (input: string) =>
  of(isNaN(Number(input)) ? 'should be valid number' : undefined);

export const isRequiredValidator: Validator = (input: string) => of(input === '' ? 'should not be empty' : undefined);

export const useIsExactlyValidator = (value: string): Validator =>
  useCallback((input: string) => of(input === value ? undefined : `should be '${value}'`), [value]);

interface DistributionError {
  error: 'stream-mismatch';
}

export const distValidation = (d: DistributionStream): DistributionError | undefined => {
  const index =
    d.publishedBucketsRule.findIndex(
      (pub) => false === d.streamAliasRule.some((alias) => alias.ecn === pub.ecn && alias.result === pub.streamAlias),
    ) ||
    d.streamAliasRule.findIndex(
      (alias) =>
        false === d.publishedBucketsRule.some((pub) => alias.ecn === pub.ecn && alias.result === pub.streamAlias),
    );
  if (index !== -1) {
    return { error: 'stream-mismatch' };
  }
  return undefined;
};

interface ClientError {
  error: 'no-duplicate';
  type: 'rfs' | 'algo';
  value: Duplicate;
}
interface Duplicate {
  first_index: number;
  second_index: number;
  value: string;
}
export const clientValidation = (d: Client): ClientError[] => [];
export const translateMessage = (d: DistributionError | ClientError) => {
  switch (d.error) {
    case 'stream-mismatch':
      return `Found mismatch between stream alias and published stream. The ecn and stream alias should be found on stream alias and the other way arround`;
    case 'no-duplicate':
      return `Duplicate found for ${d.type}, ${d.value.value} found one both line ${d.value.first_index} and ${d.value.second_index}`;
  }
};
