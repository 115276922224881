import {
  cancelConfirm,
  cancelEdit,
  createLine,
  deleteLine,
  Entity,
  EntityTableName,
  fromAlgoStream,
  fromClientStream,
  fromDistStream,
  fromMassUploadStream,
  newAlgoStreamEntity,
  newDistributionStreamEntity,
  registerSaved,
  startEditing,
  startValidating,
  toConfirm,
  updateName,
} from 'model/entity';
import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';
import { nextId } from 'utils/ids';

export interface EntityListState {
  readonly entities: readonly Entity[];
}

const initialState: EntityListState = {
  entities: [],
};

export const createTempId = (existings: readonly number[]) => {
  const values = existings.map(Math.abs);
  const max = values.reduce((c, n) => (c > n ? c : n), 0);
  return -max - 1;
};

export const createTempIds = (count: number) => {
  const existings: number[] = [];

  for (let index = 0; index < count; index++) {
    existings.push(createTempId(existings));
  }
  return existings;
};

const duplicateArrayWithNewIds = <T extends { id?: number }>(array: readonly T[]) => {
  const ids = createTempIds(array.length);
  return array.map((element, i) => ({ ...element, id: ids[i] }));
};

type EntityList = readonly Entity[];
type EntityId = number;
const addEntity = (entity: Entity, entities: EntityList): EntityList => [entity, ...entities];
const removeEntity = (entity: EntityId, entities: EntityList): EntityList => entities.filter((c) => c.id !== entity);
const putUpEntity = (entity: Entity, entities: EntityList): EntityList => [
  entity,
  ...entities.filter((c) => c !== entity),
];

const updateEntity = (id: number, f: (e: Entity) => Entity, entities: EntityList) =>
  entities.map((entity) => (entity.id !== id ? entity : f(entity)));

const formChanged = (
  id: number,
  tableName: string,
  columnName: string,
  rowIndex: number,
  value: string,
  entities: EntityList,
) =>
  updateEntity(
    id,
    (entity) =>
      ({
        ...entity,
        value: {
          ...entity.value,
          [tableName]: entity.value[tableName].map((row: any, index: number) =>
            index !== rowIndex ? row : { ...row, [columnName]: value },
          ),
        },
      } as Entity),
    entities,
  );

const formLineDeleted = (id: number, tableName: EntityTableName, rowIndex: number, entities: EntityList) =>
  updateEntity(id, (entity) => deleteLine(rowIndex, tableName, entity), entities);

const insertAt = <T>(index: number, value: T, list: readonly T[]) => [
  ...list.slice(0, index + 1),
  value,
  ...list.slice(index + 1),
];

const formLineAdded = (id: number, tableName: string, afterIndex: number | undefined, entities: EntityList) => {
  const splitIndex = afterIndex === undefined ? -1 : afterIndex;
  return updateEntity(
    id,
    (entity) => {
      const rowId = createTempId(entity.value[tableName].map((c: any) => c.id as number));
      return {
        ...entity,
        value: {
          ...entity.value,
          [tableName]: insertAt(splitIndex, createLine(entity.type, tableName, rowId), entity.value[tableName]),
        },
      } as Entity;
    },
    entities,
  );
};

export const entityListReducer: Reducer<EntityListState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'EntityList.displayClient':
      const clientEntity = fromClientStream(nextId(state.entities), action.client);

      return { ...state, entities: addEntity(clientEntity, state.entities) };
    case 'EntityList.massUpload':
      const massUploadEntity = fromMassUploadStream(nextId(state.entities), action.client);

      return { ...state, entities: addEntity(massUploadEntity, state.entities) };

    case 'EntityList.displayAlgoStream':
      const algoEntity = fromAlgoStream(nextId(state.entities), action.algoStream);

      return { ...state, entities: addEntity(algoEntity, state.entities) };

    case 'EntityList.displayDistributionStream':
      const distributionEntity = fromDistStream(nextId(state.entities), action.algoStream);

      return { ...state, entities: addEntity(distributionEntity, state.entities) };

    case 'EntityList.removeEntity':
      return { ...state, entities: removeEntity(action.entityId, state.entities) };

    case 'EntityList.highlightEntity':
      return {
        ...state,
        entities: putUpEntity(action.entity, state.entities),
      };

    case 'EntityList.editEntity':
      return {
        ...state,
        entities: updateEntity(action.entityId, startEditing, state.entities),
      };
    case 'EntityList.editFormChanged':
      return {
        ...state,
        entities: formChanged(
          action.entityId,
          action.tableName,
          action.columnName,
          action.rowId,
          action.value,
          state.entities,
        ),
      };

    case 'EntityList.savedEntity':
      return {
        ...state,
        entities: updateEntity(action.entityId, (e) => registerSaved(action.valueId, e), state.entities),
      };

    case 'EntityList.deleteLine':
      return {
        ...state,
        entities: formLineDeleted(action.entityId, action.table, action.line, state.entities),
      };

    case 'EntityList.addLine':
      return {
        ...state,
        entities: formLineAdded(action.entityId, action.table, action.afterIndex, state.entities),
      };
    case 'EntityList.cancelEdit':
      return {
        ...state,
        entities: updateEntity(action.entityId, cancelEdit, state.entities),
      };

    case 'EntityList.newAlgoStream':
      return {
        ...state,
        entities: addEntity(newAlgoStreamEntity(nextId(state.entities)), state.entities),
      };

    case 'EntityList.newDistributionStream':
      return {
        ...state,
        entities: addEntity(newDistributionStreamEntity(nextId(state.entities)), state.entities),
      };

    case 'EntityList.duplicateDistributionStream':
      const entityToDuplicate = state.entities.find((entity) => entity.id === action.entityIdToDuplicate);
      if (entityToDuplicate && entityToDuplicate.type === 'distribution-stream') {
        const newEntity: Entity = {
          ...newDistributionStreamEntity(nextId(state.entities)),
          value: {
            id: undefined,
            name: '',
            numberLinked: 0,
            marginCategoryRule: duplicateArrayWithNewIds(entityToDuplicate.value.marginCategoryRule),
            publishedBucketsRule: duplicateArrayWithNewIds(entityToDuplicate.value.publishedBucketsRule),
            streamAliasRule: duplicateArrayWithNewIds(entityToDuplicate.value.streamAliasRule),
            tradingGroupRule: duplicateArrayWithNewIds(entityToDuplicate.value.tradingGroupRule),
          },
        };
        return {
          ...state,
          entities: addEntity(newEntity, state.entities),
        };
      } else {
        return state;
      }

    case 'EntityList.updateTitle':
      return {
        ...state,
        entities: updateEntity(action.entityId, (e) => updateName(action.title, e), state.entities),
      };

    case 'EntityList.askConfirm':
      return {
        ...state,
        entities: updateEntity(action.entityId, toConfirm, state.entities),
      };

    case 'EntityList.cancelConfirm':
      return {
        ...state,
        entities: updateEntity(action.entityId, cancelConfirm, state.entities),
      };
    case 'EntityList.validating':
      return {
        ...state,
        entities: updateEntity(action.entityId, startValidating, state.entities),
      };
    default:
      return state;
  }
};
