import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';

export interface ClientSearchFormState {
  readonly bdrId: string;
}

const initialState: ClientSearchFormState = {
  bdrId: '',
};

export const clientSearchReducer: Reducer<ClientSearchFormState, AppAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'SearchForm.Client.bdrInputChanged':
      return {
        ...state,
        bdrId: action.bdrId,
      };

    case 'SearchForm.Client.formSubmitted':
      return {
        ...state,
        bdrId: state.bdrId.trim(),
      };

    case 'SearchForms.reset':
      return { ...state, bdrId: initialState.bdrId };
    default:
      return state;
  }
};
