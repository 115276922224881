import { execRequest } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

export const getFilteredBdrIdNotInUlysse = (bdrIds: string) =>
  execRequest({
    url: `${getConfig().api_url}api/Client/bdrids-not-ulysse`,
    body: {
      BdrIds: bdrIds,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
