import { Client } from 'model/client';
import { Epic, ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import { displayClient, fetchClientDetails, highlightEntity } from 'store/state/entity-list/entity-list.actions';
import { EntityListState } from 'store/state/entity-list/entity-list.reducer';
import { getJSON } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

const getByBdrId = (bdrId: string) => getJSON<Client>(`${getConfig().api_url}api/client/` + bdrId);
export const addClient = (
  requestResults: (s: string) => Observable<Client>,
  s: ReturnType<typeof fetchClientDetails>,
  entityList: EntityListState,
): Observable<AppAction> => {
  const entity = entityList.entities.find((c) => c.type === 'client' && c.value.bdrId === s.client.bdrId);

  if (entity !== undefined) {
    return of(highlightEntity(entity));
  }

  if ('executionPreventionRule' in s.client) {
    return of(displayClient(s.client));
  }

  return requestResults(s.client.bdrId).pipe(
    map(displayClient),
    catchError((e) => {
      console.error(e);
      return of(addAlert('error while fetching client detail'));
    }),
  );
};

export const clientDetailsEpic =
  (requestResults = getByBdrId): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('EntityList.fetchClientDetails' as const),
      withLatestFrom(state$),
      switchMap(([s, { entityList }]) => addClient(requestResults, s, entityList)),
    );
