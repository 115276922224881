import { Message } from 'model/Alert';
import { Reducer } from 'redux';
import { AppAction } from 'store/app.actions';
import { nextId } from 'utils/ids';
type MessageList = Message[];
interface AlertState {
  messages: MessageList;
}
const defaultState: AlertState = {
  messages: [],
};
export const alertsReducer: Reducer<AlertState, AppAction> = (state = defaultState, action) => {
  switch (action.type) {
    case 'Alerts.add':
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            id: nextId(state.messages),
            text: action.message,
          },
        ],
      };
    case 'Alerts.remove':
      return {
        ...state,
        messages: state.messages.filter((c) => c.id !== action.id),
      };
    default:
      return state;
  }
};
