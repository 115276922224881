import { Editable, LimitedEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import { isRequiredValidator } from 'app/utils/validators';
import { StreamAliasRule } from 'model/distribution-stream';
import React, { useCallback, useMemo } from 'react';
import { CommonRowProps } from '../util/table';
import { DistributionStreamRuleTableProps } from './types';

interface StreamAliasRuleTableProps extends DistributionStreamRuleTableProps<'streamAliasRule'> {
  ecns: readonly string[];
}

export function StreamAliasRuleTable(props: StreamAliasRuleTableProps) {
  const table = useMemo(() => createTable(props.values, ['ecn', 'result', 'id']), [props.values]);

  return (
    <table className={`table table-bordered editable-table ${props.display ? '' : 'd-none'}`}>
      <thead>
        <tr>
          <th>Ecn</th>
          <th className="result-column">Ecn Alias</th>
          {props.editing && (
            <th>
              <Icon type="add" onClick={() => props.addLine()} color="info" />{' '}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <EditableTable<keyof StreamAliasRule, StreamAliasRule>
          values={table}
          editing={props.editing}
          useOtherConditionGet={false}
          rowId="id"
          resultKey="result"
          size={2}
          addLine={props.addLine}
          deleteLine={props.deleteLine}
        >
          {(value, index) => (
            <StreamAliasRule
              value={value}
              ecns={props.ecns}
              editing={props.editing}
              index={index}
              onlyResult={false}
              onChange={props.onChange}
            />
          )}
        </EditableTable>
      </tbody>
    </table>
  );
}

interface StreamAliasRuleProps extends CommonRowProps<StreamAliasRule> {
  ecns: readonly string[];
}

const StreamAliasRule = (props: StreamAliasRuleProps) => {
  const ecnChange = useCallback(
    (value: string) => props.onChange('ecn', props.index, value),
    [props.onChange, props.index],
  );
  const resultChange = useCallback(
    (value: string) => props.onChange('result', props.index, value),
    [props.onChange, props.index],
  );
  return (
    <>
      <Cell editing={props.editing} length={props.value.ecn.length} displayed={props.value.ecn.displayed}>
        <LimitedEditable
          possibleValues={props.ecns}
          editing={props.editing}
          value={props.value.ecn.value}
          onChange={ecnChange}
        />
      </Cell>
      <Cell editing={props.editing} length={props.value.result.length} displayed={props.value.result.displayed} result>
        <Editable
          editing={props.editing}
          value={props.value.result.value}
          onChange={resultChange}
          validate={isRequiredValidator}
        />
      </Cell>
    </>
  );
};
