import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store/app.state';
import {
  bdrInputChanged,
  formSubmitted,
} from 'store/state/search/client-search-by-stream-name-form/client-search-by-stream-name-form.actions';
import { SearchForm } from './search-form';

interface BdrIdSearchFormProps {
  name: string;
  bdrInputChanged: (bdrId: string) => void;
  formSubmitted: () => void;
}

const ClientSearchByAlgoNameForm = (props: BdrIdSearchFormProps) => (
  <SearchForm
    title="Stream Name"
    formSubmitted={props.formSubmitted}
    value={props.name}
    valueChanged={props.bdrInputChanged}
  />
);

const mapStateToProps = (state: AppState) => ({
  name: state.clientSearchByStreamNameForm.name,
});

const mapDispatchToProps = {
  bdrInputChanged,
  formSubmitted,
};

export const ClientSearchByAlgoNameFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClientSearchByAlgoNameForm);
