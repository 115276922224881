export interface TableCell<T> {
  displayed: boolean;
  length: number;
  value: T;
}

export type RowOf<T> = { [P in keyof T]: TableCell<T[P]> };
export type TableBody<T> = Array<RowOf<T>>;
export interface Table<T> {
  values: TableBody<T>;
  end: RowOf<T> | undefined;
}

export function createTable<T>(values: readonly T[], props: ReadonlyArray<keyof T>): Table<T> {
  if (values.length === 0) {
    return {
      values: [],
      end: undefined,
    };
  }
  if (values.length === 1) {
    return {
      values: [],
      end: createRow(values[0], props),
    };
  }

  const table: TableBody<T> = [createRow(values[0], props)];

  for (const value of values.slice(1, values.length - 1)) {
    addRow(table, value, props);
  }

  return {
    values: table,
    end: createRow(values[values.length - 1], props),
  };
}

function createRow<T>(t: T, props: ReadonlyArray<keyof T>) {
  const row: RowOf<T> = {} as RowOf<T>;
  for (const p of props) {
    row[p] = newCell(t[p]);
  }
  return row;
}

function lastOf<T, K extends keyof T>(table: TableBody<T>, column: K): TableCell<T[K]> {
  // since FirstRowOf allaws have value everywhere(and always in tables), never undefined
  return table.reduce<TableCell<unknown> | undefined>((current, nextRow) => {
    const nextCell = nextRow[column];
    return nextCell.displayed ? nextCell : current;
  }, undefined) as TableCell<T[K]>;
}

function newCell<T>(t: T): TableCell<T> {
  return {
    length: 1,
    value: t,
    displayed: true,
  };
}

function addRow<T>(table: TableBody<T>, rule: T, columns: ReadonlyArray<keyof T>): void {
  const newRow: RowOf<T> = {} as RowOf<T>;
  let newPart = false;
  for (const column of columns) {
    newRow[column] = updateCell(newPart, lastOf(table, column), rule[column]);
    newPart = newPart || newRow[column].displayed;
  }
  table.push(newRow);
}

function updateCell<T>(newPart: boolean, c: TableCell<T>, t: T): TableCell<T> {
  if (!newPart && c.value === t) {
    c.length += 1;
    return {
      length: 1,
      value: t,
      displayed: false,
    };
  } else {
    return newCell(t);
  }
}
