import React, { ReactNode } from 'react';

export function Modal(props: { children: ReactNode }) {
  return (
    <div className="modal show" tabIndex={-1} role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content shadow-max">{props.children}</div>
      </div>
    </div>
  );
}

export function ModalHeader(props: { children: ReactNode; onClose: () => void }) {
  return (
    <div className="modal-header">
      <h3 className="modal-title">{props.children}</h3>
      <button type="button" className="close icon" data-dismiss="moal" aria-label="Close" onClick={props.onClose}>
        close
      </button>
    </div>
  );
}
export function ModalBody(props: { children: ReactNode }) {
  return <div className="modal-body">{props.children}</div>;
}

export function ModalFooter(props: { children: ReactNode }) {
  return <div className="modal-footer">{props.children}</div>;
}

export function ModalToggle(props: { id: string; children: ReactNode; modal: ReactNode }) {
  return (
    <span id={`modal-${props.id}`}>
      <span data-toggle="modal" data-target={`#modal-${props.id}>.modal`}>
        {props.children}
      </span>
      {props.modal}
    </span>
  );
}
