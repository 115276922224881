import { AlgoStream } from 'model/algo-stream';
import { Client, ClientOverview } from 'model/client';
import { DistributionStream } from 'model/distribution-stream';
import { Entity, EntityTableName } from 'model/entity';

export const displayClient = (client: Client) =>
  ({
    type: 'EntityList.displayClient',
    client,
  } as const);

export const displayMassUpload = (client: Client) =>
  ({
    type: 'EntityList.massUpload',
    client,
  } as const);

export const displayAlgoStream = (algoStream: AlgoStream) =>
  ({
    type: 'EntityList.displayAlgoStream',
    algoStream,
  } as const);

export const displayDistributionStream = (algoStream: DistributionStream) =>
  ({
    type: 'EntityList.displayDistributionStream',
    algoStream,
  } as const);

export const removeEntity = (entityId: number) =>
  ({
    type: 'EntityList.removeEntity',
    entityId,
  } as const);

export const fetchAlgoStreamDetails = (name: string) =>
  ({
    type: 'EntityList.fetchAlgoStreamDetails',
    name,
  } as const);

export const fetchDistributionStreamDetails = (name: string) =>
  ({
    type: 'EntityList.fetchDistributionStreamDetails',
    name,
  } as const);

export const fetchClientDetails = (client: Client | ClientOverview) =>
  ({
    type: 'EntityList.fetchClientDetails',
    client,
  } as const);

export const highlightEntity = (entity: Entity) =>
  ({
    type: 'EntityList.highlightEntity',
    entity,
  } as const);

export const editEntity = (entityId: number) =>
  ({
    type: 'EntityList.editEntity',
    entityId,
  } as const);

export const editFormChanged = (
  entityId: number,
  tableName: EntityTableName,
  columnName: string,
  rowId: number,
  value: string,
) =>
  ({
    type: 'EntityList.editFormChanged',
    entityId,
    tableName,
    columnName,
    rowId,
    value,
  } as const);

export const saveEntity = (entityId: number) =>
  ({
    type: 'EntityList.saveEntity',
    entityId,
  } as const);

export const savedEntity = (entityId: number, valueId: number | undefined) =>
  ({
    type: 'EntityList.savedEntity',
    entityId,
    valueId,
  } as const);

export const deleteLine = (entityId: number, table: EntityTableName, line: number) =>
  ({
    type: 'EntityList.deleteLine',
    line,
    entityId,
    table,
  } as const);

export const addLine = (entityId: number, table: EntityTableName, afterIndex?: number) =>
  ({
    type: 'EntityList.addLine',
    entityId,
    table,
    afterIndex,
  } as const);

export const cancelEdit = (entityId: number) =>
  ({
    type: 'EntityList.cancelEdit',
    entityId,
  } as const);

export const newDistributionStream = () => ({ type: 'EntityList.newDistributionStream' } as const);

export const duplicateDistributionStream = (entityIdToDuplicate: number) =>
  ({ type: 'EntityList.duplicateDistributionStream', entityIdToDuplicate } as const);

export const newAlgoStream = () => ({ type: 'EntityList.newAlgoStream' } as const);

export const updateTitle = (entityId: number, title: string) =>
  ({ type: 'EntityList.updateTitle', entityId, title } as const);

export const askConfirm = (entityId: number) => ({ type: 'EntityList.askConfirm', entityId } as const);

export const cancelConfirm = (entityId: number) => ({ type: 'EntityList.cancelConfirm', entityId } as const);

export const validating = (entityId: number) => ({ type: 'EntityList.validating', entityId } as const);
