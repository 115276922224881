import { combineEpics } from 'redux-observable';
import { algoStreamDetailsEpic } from './epic/algo-stream-details.epic';
import { algoStreamSearchEpic } from './epic/algo-stream-search.epic';
import { clientSearchByStreamNameEpic } from './epic/client-search-by-stream-name.epic';
import { clientSearchEpic } from './epic/client-search.epic';
import { clientDetailsEpic } from './epic/client-search.epic.details';
import { distributionStreamDetailsEpic } from './epic/distribution-stream-details.epic';
import { distributionStreamSearchEpic } from './epic/distribution-stream-search.epic';
import { referentialInitEpic } from './epic/referentials.epic';
import { saveEntityEpic } from './epic/save-entity.epic';
import { userInitEpic } from './epic/user.epic';

export const appEpic = combineEpics(
  clientSearchEpic(),
  clientSearchByStreamNameEpic(),
  algoStreamSearchEpic(),
  distributionStreamSearchEpic(),
  algoStreamDetailsEpic(),
  distributionStreamDetailsEpic(),
  clientDetailsEpic(),
  referentialInitEpic(),
  saveEntityEpic(),
  userInitEpic(),
);
