import { Client } from 'model/client';
import { Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppAction } from 'store/app.actions';
import { AppState } from 'store/app.state';
import { addAlert } from 'store/state/alerts/alerts.actions';
import { singleClientSearchSuccess } from 'store/state/search-result/search-result.actions';
import { getJSON } from 'utils/http';
import { getConfig } from 'utils/sgwtConnect';

const searchByBdrId = (bdrId: string) => getJSON<Client>(`${getConfig().api_url}api/client/` + bdrId);

export const clientSearchEpic =
  (requestResults = searchByBdrId): Epic<AppAction, AppAction, AppState> =>
  (action$, state$) =>
    action$.pipe(
      ofType('SearchForm.Client.formSubmitted' as const),
      withLatestFrom(state$),
      switchMap(([, { clientSearchForm }]) =>
        requestResults(clientSearchForm.bdrId.trim()).pipe(
          map(singleClientSearchSuccess),
          catchError((e: any) => of(addAlert('Client search failed'))),
        ),
      ),
    );
