import { Editable, LimitedEditable, TenorEditable } from 'app/utils/editable';
import { Cell, EditableTable } from 'app/utils/editabletable';
import { Icon } from 'app/utils/icons';
import { createTable } from 'app/utils/table';
import { useCurrencyValidator, Validator } from 'app/utils/validators';
import { TradingGroupRule } from 'model/distribution-stream';
import React, { memo, useCallback, useMemo } from 'react';
import { CommonRowProps } from '../util/table';
import { DistributionStreamRuleTableProps } from './types';

interface TradingGroupRuleTableProps extends DistributionStreamRuleTableProps<'tradingGroupRule'> {
  products: readonly string[];
  tenors: readonly string[];
  currencies: readonly string[];
  tradingGroups: readonly string[];
}

export const TradingGroupRuleTable = memo(function TradingGroupRuleTable_(props: TradingGroupRuleTableProps) {
  const tradingGroupRule = useMemo(
    () => createTable(props.values, ['product', 'currency', 'tenor', 'result', 'id']),
    [props.values],
  );

  const validateCurrency = useCurrencyValidator(props.currencies);
  return (
    <table className={`table table-bordered editable-table ${props.display ? '' : 'd-none'}`}>
      <thead>
        <tr>
          <th style={{ width: '8em' }}>Product</th>
          <th>Currency</th>
          <th>Tenor</th>
          <th style={{ width: '8em' }} className="result-column">
            Result
          </th>
          {props.editing && (
            <th>
              <Icon type="add" onClick={() => props.addLine()} color="info" />{' '}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        <EditableTable<keyof TradingGroupRule, TradingGroupRule>
          values={tradingGroupRule}
          useOtherConditionGet
          editing={props.editing}
          rowId="id"
          resultKey="result"
          size={4}
          defaultResult="REJECTED"
          addLine={props.addLine}
          deleteLine={props.deleteLine}
        >
          {(value, index, onlyResult) => (
            <TradingGroupRow
              value={value}
              index={index}
              onlyResult={onlyResult}
              editing={props.editing}
              onChange={props.onChange}
              products={props.products}
              tenors={props.tenors}
              tradingGroups={props.tradingGroups}
              validateCurrency={validateCurrency}
            />
          )}
        </EditableTable>
      </tbody>
    </table>
  );
});

interface TradingGroupRuleProps extends CommonRowProps<TradingGroupRule> {
  products: readonly string[];
  tenors: readonly string[];
  tradingGroups: readonly string[];
  validateCurrency: Validator;
}
const defaultResult = { value: '', label: 'REJECTED' };
const TradingGroupRow = (props: TradingGroupRuleProps) => {
  const productChanged = useCallback(
    (value: string) => props.onChange('product', props.index, value),
    [props.onChange, props.index],
  );
  const currencyChanged = useCallback(
    (value: string) => props.onChange('currency', props.index, value),
    [props.onChange, props.index],
  );
  const tenorChanged = useCallback(
    (value: string) => props.onChange('tenor', props.index, value),
    [props.onChange, props.index],
  );
  const resultChanged = useCallback(
    (value: string) => props.onChange('result', props.index, value),
    [props.onChange, props.index],
  );

  return (
    <>
      <Cell editing={props.editing} displayed={props.value.product.displayed} length={props.value.product.length}>
        <LimitedEditable
          defaultValue="*"
          disabled={props.onlyResult}
          possibleValues={props.products}
          editing={props.editing}
          value={props.value.product.value}
          onChange={productChanged}
        />
      </Cell>
      <Cell editing={props.editing} displayed={props.value.currency.displayed} length={props.value.currency.length}>
        <Editable
          validate={props.validateCurrency}
          disabled={props.onlyResult}
          editing={props.editing}
          value={props.value.currency.value}
          onChange={currencyChanged}
        />
      </Cell>
      <Cell editing={props.editing} displayed={props.value.tenor.displayed} length={props.value.tenor.length}>
        <TenorEditable
          disabled={props.onlyResult}
          possibleValues={props.tenors}
          editing={props.editing}
          value={props.value.tenor.value}
          onChange={tenorChanged}
        />
      </Cell>
      <Cell editing={props.editing} displayed={props.value.result.displayed} length={props.value.result.length} result>
        <LimitedEditable
          possibleValues={props.tradingGroups}
          editing={props.editing}
          value={props.value.result.value || 'REJECTED'}
          onChange={resultChanged}
          defaultValue={defaultResult}
        />
      </Cell>
    </>
  );
};
