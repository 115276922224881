export const algoStreamNameChanged = (name: string) =>
  ({
    type: 'SearchForm.AlgoStream.nameChanged',
    name,
  } as const);

export const algoStreamFormSubmitted = () =>
  ({
    type: 'SearchForm.AlgoStream.formSubmitted',
  } as const);
