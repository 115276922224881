type Currency = string;

export function isValidCurrency(currency: Currency, currencyList: readonly Currency[]): boolean {
  if (currency === '*' || currency === 'group.G10' || currency === 'group.EMERGING') {
    return true;
  }

  const currencies = currency.match(/^(.{3})\/(.{3})\*2$|^(.{3})\/(.{3})$|^(.{3})$/);
  if (currencies) {
    const cs = currencies.slice(1).filter((c) => c !== undefined);
    return cs.every((c) => currencyList.some((d) => c === d)) && new Set(cs).size === cs.length;
  } else {
    return false;
  }
}
