import React from 'react';
import { AlertsConnected } from './alerts/alerts';
import { EntityListConnected } from './entity-list/entity-list';
import { Footer } from './Footer';
import { Header } from './Header';
import { SearchColumn } from './search-column/search-column';
import { SearchResultGridConnected } from './search-result-grid/search-result-grid';

export function App() {
  return (
    <>
      <div className="my-container">
        <AlertsConnected />
        <Header />
        <div className="row">
          <div className="search-form-column main-column col">
            <SearchColumn />
          </div>
          <div className="search-result-column main-column col">
            <SearchResultGridConnected />
          </div>
          <div className="details-column main-column col-7" style={{ overflow: 'overlay' }}>
            <EntityListConnected />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
