export const distributionStreamNameChanged = (name: string) =>
  ({
    type: 'SearchForm.DistributionStream.nameChanged',
    name,
  } as const);

export const distributionStreamFormSubmitted = () =>
  ({
    type: 'SearchForm.DistributionStream.formSubmitted',
  } as const);
