export const addAlert = (message: string) =>
  ({
    type: 'Alerts.add',
    message,
  } as const);

export const removeAlert = (id: number) =>
  ({
    type: 'Alerts.remove',
    id,
  } as const);
